import React from "react";
import { AuthProvider } from "./auth-context";
import { ReplacementScheduleProvider } from "./replacementSchedule-context";
import { AnalysisResultProvider } from "./analysis-result";
import { UserProvider } from "./user-context";
import { MaintananceProvider } from "./maintainance-context";
import { FuelAnalysisProvider } from "./fuelAnalysis-context";
import { FleetListProvider } from "./Fleet-context";
import { DealerProvider } from "./dealer-context";
import { CompanyProvider } from "./company-context";
import { YearProvider } from "./year-context";
import { MakeProvider } from "./make-context";
import { TypeListProvider } from "./typeList-context";
import { StateListProvider } from "./state-context";
import { ModelProvider } from "./model-context";
import { DriveTrainListProvider } from "./driveTrain-context";
import { FuelListProvider } from "./fuelList-context";
import { FundListProvider } from "./fundList-context";
import { NewVehicleProvider } from "./newVehicle-context";
import { DealerCompanyProvider } from "./dealer-company-context";
import { FuelEconomyProvider } from "./fuelEconomy-context";
import { UserTypeProvider } from "./userTypeApi-context";
import { HelpDeskProvider } from "./helpdesk-context";
import { CommentsProvider } from "./comments-context";

const ProvidersWrapper = ({ children }) => {
  const providers = {
    AuthProvider,
    ReplacementScheduleProvider,
    FleetListProvider,
    DealerProvider,
    AnalysisResultProvider,
    UserProvider,
    MaintananceProvider,
    FuelAnalysisProvider,
    CompanyProvider,
    YearProvider,
    MakeProvider,
    TypeListProvider,
    StateListProvider,
    ModelProvider,
    DriveTrainListProvider,
    FuelListProvider,
    FundListProvider,
    NewVehicleProvider,
    DealerCompanyProvider,
    FuelEconomyProvider,
    UserTypeProvider,
    HelpDeskProvider,
    CommentsProvider,
  };
  const ProviderComponents = Object.values(providers);
  return (
    <>
      {ProviderComponents.reduceRight(
        (acc, Comp) => (
          <Comp>{acc}</Comp>
        ),
        children
      )}
    </>
  );
};

export default ProvidersWrapper;
