import React from "react";
import Button from "@mui/material/Button";
const ButtonComponent = ({
  onClick,
  children,
  variant,
  icon: Icon,
  color,
  type,
  style,
  disable
}) => {
  return (
    <Button
      variant={variant}
      onClick={onClick}
      type={type}
      color={color}
      disabled={disable}
      style={style}
    >
      {Icon && Icon}
      {children}
    </Button>
  );
};

export default ButtonComponent;
