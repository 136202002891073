import React, { useState, createContext, useCallback, useContext } from "react";
import api from "../../configs/api";

/**
 * Fuel list context
 */
export const FuelListContext = createContext();

/**
 * Fuel list provider
 */
export const FuelListProvider = ({ children }) => {
  const [fuelList, setFuelList] = useState([]);
  const [fuelListById, setFuelListById] = useState(null);
  const [loading, setLoading] = useState(false);

  const getFuelListApi = async (values) => {
    setLoading(true);
    const response = await api.fuelListApi.getFuelList({
      limit: values?.limit ? values.limit : 100,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setFuelList(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const getFuelListByIdApi = async (id) => {
    setLoading(true);
    const response = await api.fuelListApi.getFuelListByid(id);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setFuelListById(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const createFuelListApi = async (values) => {
    setLoading(true);
    const response = await api.fuelListApi.createFuelList(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setFuelList(response?.data?.data);
      getFuelListApi();
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const editFuelListApi = async (values) => {
    setLoading(true);
    const response = await api.fuelListApi.editFueltList(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      getFuelListApi();
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const deleteFuelListByIdApi = async (values) => {
    setLoading(true);
    const response = await api.fuelListApi.deleteFuelListByid(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }
    if (response?.data?.data) {
      getFuelListApi();
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const contextValue = {
    fuelList,
    setFuelList,
    loading,
    setLoading,
    setFuelListById,
    fuelListById,
    getFuelListApi: useCallback((request) => {
      getFuelListApi(request);
    }, []),
    createFuelListApi: useCallback((request) => {
      createFuelListApi(request);
    }, []),
    editFuelListApi: useCallback((request) => {
      editFuelListApi(request);
    }, []),
    getFuelListByIdApi: useCallback((request) => {
      getFuelListByIdApi(request);
    }, []),
    deleteFuelListByIdApi: useCallback((id) => {
      deleteFuelListByIdApi(id);
    }, []),
  };

  return (
    <FuelListContext.Provider value={contextValue}>
      {children}
    </FuelListContext.Provider>
  );
};

export const useFuelList = () => useContext(FuelListContext);
