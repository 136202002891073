import { lazy } from "react";
import { routes } from "./index";
import UserSetting from "../../../pages/dashboard/UserSetting";
import HelpDesk from "../../../pages/dashboard/HelpDesk";
import { HelpDeskDealer } from "../../../pages/dashboard/HelpDeskDealer";
import { AdminViewUserQueries } from "../../../pages/dashboard/AdminViewUserQueries";

const Dashboard = lazy(() => import("../../../pages/dashboard"));
const FLeet = lazy(() => import("../../../pages/dashboard/fleet"));
const Replacement = lazy(() => import("../../../pages/dashboard/replacement"));
const FuelAnalysis = lazy(() => import("../../../pages/dashboard/fuel"));
const Funding_NewVehicle = lazy(() =>
  import("../../../pages/dashboard/funding")
);
const AnalysisResults = lazy(() =>
  import("../../../pages/dashboard/analysis-result")
);
const FuelPrice = lazy(() => import("../../../pages/dashboard/fuel-price"));
const FuelEconomy = lazy(() => import("../../../pages/dashboard/fuel-economy"));
const Report = lazy(() => import("../../../pages/dashboard/report"));
const ManageCompany = lazy(() =>
  import("../../../pages/dashboard/admin/manageCompany")
);
const ManageUser = lazy(() =>
  import("../../../pages/dashboard/admin/manageUser")
);
const ManageVehicle = lazy(() =>
  import("../../../pages/dashboard/admin/manageVehicle")
);
const NotFound = lazy(() => import("../../../components/NotFoundPage"));
const NewFleetList = lazy(() => import("../../../pages/dashboard/new-fleet"));
const Maintenance = lazy(() => import("../../../pages/dashboard/maintenance"));
const AnalysisData = lazy(() =>
  import("../../../pages/dashboard/analysis-data")
);
const AnalysisReport = lazy(() =>
  import("../../../pages/dashboard/analysis-report")
);
export const pageRoutes = [
  //Dashboards
  {
    path: routes.DASHBOARD,
    component: Dashboard,
  },
  {
    path: routes.FLEET,
    component: FLeet,
  },
  {
    path: routes.REPLACEMENT,
    component: Replacement,
  },
  {
    path: routes.MAINTENANCE,
    component: Maintenance,
  },
  {
    path: routes.FUEL,
    component: FuelAnalysis,
  },
  {
    path: routes.FUNDING,
    component: Funding_NewVehicle,
  },
  {
    path: routes.ANALYSIS_RESULTS,
    component: AnalysisResults,
  },
  {
    path: routes.FUEL_PRICE,
    component: FuelPrice,
  },
  {
    path: routes.FUEL_ECONOMY,
    component: FuelEconomy,
  },
  {
    path: routes.REPORT,
    component: Report,
  },
  {
    path: routes.MANAGE_COMPANY,
    component: ManageCompany,
  },
  {
    path: routes.MANEGE_USERS,
    component: ManageUser,
  },
  {
    path: routes.MANAGE_VEHICLE,
    component: ManageVehicle,
  },
  {
    path: routes.NOT_FOUND,
    component: NotFound,
  },
  {
    path: routes.NEW_FLEET_LIST,
    component: NewFleetList,
  },
  {
    path: routes.ANALYSIS_DATA,
    component: AnalysisData,
  },
  {
    path: routes.ANALYSIS_REPORT,
    component: AnalysisReport,
  },
  {
    path: routes.USER_PROFILE,
    component: UserSetting,
  },
  {
    path: routes.ADMIN_HELPDESK,
    component: HelpDesk,
  },
  {
    path: routes.USER_HELPDESK,
    component: HelpDeskDealer,
  },
  {
    path: routes.ADMIN_VIEW_QUERIES,
    component: AdminViewUserQueries,
  },
];
