import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#002C5F", // dark blue
      light: "#42a5f5",
      dark: "#1565c0",
      contrastText: "#fff", // white
    },
    secondary: {
      main: "#9c27b0", // purple
      light: "#ba68c8",
      dark: "#7b1fa2",
      contrastText: "#fff", // white
    },
    background: {
      default: "#F0F8FF", //Alice blue
      paper: "#F0F8FF",
    },
    text: {
      primary: "#000", //black
      secondary: "#002C5F", // dark blue
      cardtext: "#000",
    },
    textHover: {
      primary: "#1677ff", // lighht blue
    },
    myClassAppliedToGrid: {
      "& .ag-header-row-column": {
        background: "#002C5F !important",
      },
    },
  },
  typography: {
    fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
    fontSize: 10.5,
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#76b4e6", // light blue
      light: "#e3f2fd",
      dark: "#42a5f5",
      contrastText: "#ffffff", // dark gray
    },
    secondary: {
      main: "#ce93d8", // light purple
      light: "#f3e5f5",
      dark: "#ab47bc",
      contrastText: "#4c4b4b", // dark gray
    },
    background: {
      default: "#3D3D3D", // light gray
      paper: "#3D3D3D",
    },
    text: {
      primary: "#FFFFFF", // white
      secondary: "#a4a4ab", // dark gray
      cardtext: "#000",
    },
  },
  typography: {
    fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
    fontSize: 10.5,
  },
});
