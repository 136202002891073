import React, { useState, createContext, useCallback, useContext } from "react";
import api from "../../configs/api";

/**
 * State list context
 */
export const StateListContext = createContext();

/**
 * State list provider
 */
export const StateListProvider = ({ children }) => {
  const [stateList, setStateList] = useState([]);
  const [typeStateById, setStateListById] = useState(null);
  const [loading, setLoading] = useState(false);

  const getStateListApi = async (values) => {
    setLoading(true);
    const response = await api.stateListApi.getStateList({
      limit: values?.limit ? values.limit : 100,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data?.length > 0) {
      setStateList(response?.data?.data);
      setLoading(false);
    } else {
      setStateList([]);
      setLoading(false);
    }

    return response.data;
  };
  const getStateListByIdApi = async (id) => {
    setLoading(true);
    const response = await api.stateListApi.getStateListByid(id);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setStateListById(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
      setStateListById([]);
    }

    return response.data;
  };
  const createStateListApi = async (values) => {
    setLoading(true);
    const response = await api.stateListApi.createStateList(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setStateList(response?.data?.data);
      getStateListApi();
    } else {
      setStateList([]);
      setLoading(false);
    }

    return response.data;
  };
  const editStateListApi = async (values) => {
    setLoading(true);
    const response = await api.stateListApi.editStateList(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      getStateListApi();
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const contextValue = {
    stateList,
    setStateList,
    loading,
    setLoading,
    setStateListById,
    typeStateById,
    getStateListApi: useCallback((request) => {
      getStateListApi(request);
    }, []),
    createStateListApi: useCallback((request) => {
      createStateListApi(request);
    }, []),
    editStateListApi: useCallback((request) => {
      editStateListApi(request);
    }, []),
    getStateListByIdApi: useCallback((request) => {
      getStateListByIdApi(request);
    }, []),
    // deleteDealerByIdApi: useCallback((request) => {
    //   deleteDealerByIdApi(request);
    // }, []),
  };

  return (
    <StateListContext.Provider value={contextValue}>
      {children}
    </StateListContext.Provider>
  );
};

export const useStates = () => useContext(StateListContext);
