import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Drawer,
  Grid,
  InputLabel,
  Modal,
  Stack,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { HelpDeskSelect } from "../../components/HelpDeskSelect";
import { useLocation } from "react-router-dom";
import { Send } from "@mui/icons-material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useHelpDesk } from "../../common/context/helpdesk-context";
import { useComments } from "../../common/context/comments-context";
import { HelpDeskStatus } from "../../common/constant/selectConstants";
import { LayoutStyle } from "../layout/style";
import { typeConstant } from "../../common/constant/commonFunction";
import { localStorageItemNames } from "../../common/constant/local-storage-item-names";

export const AdminViewUserQueries = ({ open, setOpen, setRefreshData }) => {
  const { state } = useLocation();
  let paramsValue = state;
  const classes = LayoutStyle();
  const [binaryImage, setBinaryImage] = useState(null);
  const [description, setDescription] = useState(null);
  const [adminId, setAdminId] = useState(null);
  const userName = localStorage.getItem(localStorageItemNames.name);
  const userId = localStorage.getItem(localStorageItemNames.userID);
  let data = new FormData();
  const { createHelpDeskListApi, editHelpDeskListApi } = useHelpDesk();
  const [openModal, setOpenModal] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState("");
  const { getCommentsApi, createCommentsApi, comments, createLoading } =
    useComments();
  const [status, setStatus] = useState(paramsValue?.status);
  const userType = localStorage.getItem(localStorageItemNames.type);
  const [imageEdit, setEditImage] = useState(false);
  const [imageEditValue, setImageEditValue] = useState(null);
  const [imageData, setImageData] = useState();

  const handleImageClick = (imageUrl) => {
    setClickedImageUrl(imageUrl);
    setOpenModal(true);
  };

  const defaultValue = {
    description: "",
  };

  const { handleSubmit, setValue, getValues } = useForm({
    defaultValues: defaultValue,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
  const onSubmit = async () => {
    if (state?.id) {
      data.append("Name", state?.name);
      data.append("ImageFile", state?.image);
      data.append("UserId", state?.userId);
      data.append("Description", state?.description);
      data.append("Deleted", false);
      data.append("Status", status);
      data.append("Id", state?.id);
      await editHelpDeskListApi(data);
    } else {
      data.append("Name", userName);
      data.append("ImageFile", binaryImage);
      data.append("UserId", Number(userId));
      data.append("Description", description);
      data.append("Deleted", false);
      data.append("Status", status);
      await createHelpDeskListApi(data, userId);
    }
  };
  useEffect(() => {
    if (userType === typeConstant.admin) {
      setAdminId(userId);
    } else if (comments?.length > 0) {
      const lastComment = comments[comments.length - 1];
      if (lastComment?.adminId) {
        setAdminId(lastComment.adminId);
      }
    }
  }, [comments]);

  useEffect(() => {
    data.delete("ImageFile");
    data.delete("Name");
    data.delete("Deleted");
  }, [open]);

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };
  const handleCloseImageModal = () => {
    setOpenModal(false);
  };
  const ImageModal = () => {
    return (
      <React.Fragment>
        <Modal
          open={openModal}
          onClose={handleCloseImageModal}
          disableEnforceFocus
        >
          <Box
            sx={{
              position: "absolute",
              outline: "none",
              overflow: "hidden",
              margin: "20px auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "70%",
                height: "auto",
                bgcolor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: 24,
                borderRadius: 4,
              }}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "rgba(0, 0, 0, 0.54)",
                }}
                onClick={handleCloseImageModal}
              >
                <CloseIcon />
              </IconButton>
              <img
                src={
                  imageEditValue
                    ? imageEditValue
                    : `data:image/png;base64,${clickedImageUrl}`
                }
                alt="HelpDesk"
                width={"100%"}
                height={"300px"}
              />
            </Box>
          </Box>
        </Modal>
      </React.Fragment>
    );
  };
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const handleChat = async () => {
    const payload = {
      userName: userName,
      comments: description,
      userId: state.userId,
      adminId: adminId,
    };
    await createCommentsApi(payload);
    setDescription("");
  };

  useEffect(() => {
    if (state?.id) {
      getCommentsApi({
        filter: { userId: state.userId, adminId: state.adminId },
      });
    }
  }, [state]);

  const handleEditImageButton = () => {
    setEditImage(true);
  };

  const handleImageChange = async (event) => {
    var file = event.target.files[0];
    setValue("ImageFile", file);
    await imageToBinary(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setImageEditValue([reader.result]);
    };
    setImageData(file);
    setEditImage(false);
  };
  const imageToBinary = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const binaryString = reader.result;
        resolve(binaryString);
      };

      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsBinaryString(file);
    });
  };
  const handleDealerSubmit = async (event) => {
    const image = getValues("ImageFile");
    data.append("Name", state?.name);
    data.append("ImageFile", imageData);
    data.append("UserId", state?.userId);
    data.append("Description", state?.description);
    data.append("Deleted", false);
    data.append("Status", state?.status);
    data.append("Id", state?.id);
    await editHelpDeskListApi(data);
  };

  return (
    <>
      <Box className={classes.mainContent}>
        <Box className={classes.helpDeskGridContant}>
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                  mt: 2,
                }}
              >
                <Box className={classes.headerNameText}>View Ticket</Box>
                {userType === typeConstant.admin && (
                  <Box className={classes.addEditGrid}>
                    <Button
                      variant="outlined"
                      className={classes.fleetListNewButton}
                      onClick={handleSubmit(onSubmit)}
                    >
                      <span style={{ fontSize: 12 }}>Save</span>
                    </Button>
                  </Box>
                )}
                {userType !== typeConstant.admin && (
                  <Box className={classes.addEditGrid}>
                    <Button
                      variant="outlined"
                      className={classes.fleetListNewButton}
                      onClick={handleSubmit(handleDealerSubmit)}
                    >
                      <span style={{ fontSize: 12 }}>Save</span>
                    </Button>
                  </Box>
                )}
              </Stack>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel className={classes.fontSize12}>Name</InputLabel>
              <TextField
                disabled
                style={{ background: "lightgray", color: "black" }}
                type="text"
                className={classes.helpTextBox}
                defaultValue={paramsValue?.name}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel className={classes.fontSize12}>
                Description
              </InputLabel>
              <TextField
                style={{ background: "lightgray", color: "black" }}
                className={classes.helpTextBox}
                type="text"
                disabled
                defaultValue={paramsValue?.description}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel className={classes.fontSize12}>Status</InputLabel>
              <HelpDeskSelect
                className={classes.selectField}
                handleChange={handleStatusChange}
                option={HelpDeskStatus}
                value={status}
                disable={userType !== typeConstant.admin ? false : true}
                width={"90%"}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <InputLabel className={classes.fontSize12}>Image</InputLabel>
              {imageEdit ? (
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  style={{
                    width: "90%",
                    height: "135px",
                    border: "1px solid black",
                    borderRadius: "0px",
                    margin: "5px",
                  }}
                >
                  <input
                    hidden
                    multiple
                    type="file"
                    onChange={handleImageChange}
                  />
                  <AddPhotoAlternateIcon
                    style={{ fontSize: 50, color: "black" }}
                  />
                </IconButton>
              ) : (
                <img
                  src={
                    imageEditValue
                      ? imageEditValue
                      : `data:image/png;base64,${paramsValue?.image}`
                  }
                  style={{ margin: 4 }}
                  height={"200px"}
                  width={"90%"}
                />
              )}
              <Button
                className={classes.PreviewButton}
                onClick={() => handleImageClick(paramsValue?.image)}
              >
                Preview
              </Button>
              {userType !== typeConstant.admin && (
                <Button
                  className={classes.EditImageButton}
                  onClick={handleEditImageButton}
                >
                  Edit
                </Button>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <InputLabel className={classes.fontSize12}>Comments</InputLabel>
              <Grid
                item
                lg={10}
                md={10}
                sm={12}
                xs={12}
                minHeight={200}
                maxHeight={200}
                overflow={"auto"}
                padding={2}
                paddingTop={0}
                border={"1px solid black"}
              >
                {comments?.map((item, index) => {
                  const displayName = item.userName;
                  const commentText = item.comments;
                  const chatClass =
                    displayName === userName
                      ? classes.chatRight
                      : classes.chatLeft;

                  return (
                    <div key={index}>
                      <p className={chatClass}>
                        {`${displayName} : ${commentText}`}
                      </p>
                    </div>
                  );
                })}
              </Grid>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 5,
                }}
              >
                <TextField
                  type="text"
                  className={classes.commentTextBox}
                  value={description}
                  onChange={handleDescription}
                />
                {createLoading ? (
                  "Sending..."
                ) : (
                  <Send onClick={handleChat} style={{ cursor: "pointer" }} />
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ width: 400 }}>
        <ImageModal />
      </Box>
    </>
  );
};
