import React, { useEffect } from "react";
import PersistentDrawerLeft from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../common/hooks";
import { localStorageItemNames } from "../../common/constant/local-storage-item-names";
import { showToast } from "../../components/Toast";

const Layout = () => {
  const navigate = useNavigate()
  const {isLoggedIn} = useAuth();
  const token = localStorage.getItem(localStorageItemNames.user_token);
  useEffect(() => {
    if(!token){
      showToast('Token is Expired. Please Login Again', 'error');
      navigate("/");
    }
  }, [token]);
  return (
    <div>
      <PersistentDrawerLeft />
      <Footer />
    </div>
  );
};

export default Layout;
