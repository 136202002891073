import React, { useState, createContext, useCallback, useContext } from "react";
import api from "../../configs/api";
import { showToast } from "../utils/Toast";
import { localStorageItemNames } from "../constant/local-storage-item-names";

/**
 * Authentication context
 */
export const UserTypeContext = createContext();

/**
 * Authentication data provider
 */
export const UserTypeProvider = ({ children }) => {
  const [userTypeDropdown, setUserType] = useState([]);
  const [userTypeData, setUserTypeData] = useState([]);
  // const [typeId, setTypeId] = useState([])
  const [loading, setLoading] = useState(false);
  const [selectedNav, setSelectedNav] = useState("Dashboard");

  const getUserType = async (values) => {
    try {
      setLoading(true);
      const response = await api.userTypeApi.getUserTypeList({
        limit: values?.limit ? values.limit : 100,
        offset: values?.offset ? values.offset : 0,
        filter: {
          ...values?.filter,
        },
        sorting: {
          fieldName: "",
          direction: "",
        },
      });

      if (response?.data?.data) {
        setUserType(response.data.data);
        setUserTypeData(response.data.data);
        setLoading(false);

        // const user = response?.data?.data?.find((item) => item.id === parseInt(userId));

        // if (user) {
        //   setTypeId(user?.typeId)
        // } else {
        // }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const createUserType = async (request) => {
    const userType = localStorage.getItem("type");
    const userTypeId = localStorage.getItem(localStorageItemNames.userID);
    // setLoading(true);
    try {
      setLoading(true);
      const response = await api.userTypeApi.createUserType(request);
      if (response.data.succeeded === true) {
        // alert(response.data.messages.length===0?"aaaa":"bbbb");/

        if (userType === "dealer") {
          getUserType({ filter: { type: userType, typeId: userTypeId } });
        } else {
          getUserType();
        }
        setLoading(false);
      } else {
        showToast(response.data.messages[0]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const updateUserType = async (request) => {
    const userType = localStorage.getItem("type");
    const userTypeId = localStorage.getItem(localStorageItemNames.userID);
    // setLoading(true);
    try {
      setLoading(false);
      const response = await api.userTypeApi.updateUserType(request);
      if (response.data.succeeded === true) {
        if (userType === "dealer") {
          getUserType({
            filter: { type: userType, typeId: Number(userTypeId) },
          });
        } else {
          getUserType();
        }
        setUserTypeData(null);
        setLoading(false);

        return response;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteUser = async (id) => {
    const userType = localStorage.getItem("type");
    const userTypeId = localStorage.getItem(localStorageItemNames.userID);
    // setLoading(true);
    try {
      // setLoading(true)
      const response = await api.userApi.deleteUser(id);
      if (response.data.succeeded === true) {
        if (userType !== "admin") {
          getUserType({ filter: { type: userType, typeId: userTypeId } });
        } else {
          getUserType();
        }
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false)
    }
  };
  const getUserTypeById = async (values) => {
    try {
      setLoading(true);
      const response = await api.userTypeApi.getUserTypeById(values);

      if (response?.data) {
        setUserTypeData(response.data);
      } else {
        setUserTypeData([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const getUserTypeSpecificId = async (values) => {
    try {
      setLoading(true);
      const response = await api.userTypeApi.getUserTypeSpecificId(values);

      if (response?.data?.data) {
        setUserTypeData(response.data.data);
      } else {
        setUserTypeData([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const contextValue = {
    userTypeDropdown,
    loading,
    setUserType,
    createUserType,
    getUserType,
    setUserTypeData,
    updateUserType,
    userTypeData,
    selectedNav,
    setSelectedNav,
    getUserTypeSpecificId,
  };

  return (
    <UserTypeContext.Provider value={contextValue}>
      {children}
    </UserTypeContext.Provider>
  );
};

export const useUserType = () => useContext(UserTypeContext);
