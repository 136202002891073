export const TAB_VALUE = {
  FLEET_LIST: 5,
  REPLACEMENT_SCHEDULE: 1,
  MAINTENANCE_ANALYSIS: 2,
  FUEL_ANALYSIS: 3,
  FUNDING_NEW_VEHICLE: 4,
};

export const TAB_CONTENT = {
  FLEET_LIST: "Fleet List",
  REPLACEMENT_SCHEDULE: "Replacement Schedule",
  MAINTENANCE_ANALYSIS: "Maintenance Analysis",
  FUEL_ANALYSIS: "Fuel Analysis",
  FUNDING_NEW_VEHICLE: "Funding/New Vehicle",
};

export const PopupMessage = {
  makeSelect: "Please select same make!",
  error: "error",
  warning: "warning",
  info: "info",
  selectFile: "Please select a file!",
  uploadFile: "File is uploading Please wait!",
};

export const costPerMileKeys = [
  "currentCostPerMile",
  "year_1_CostPerMile",
  "year_2_CostPerMile",
  "year_3_CostPerMile",
  "year_4_CostPerMile",
  "year_5_CostPerMile"
];
