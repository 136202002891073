import { Box, Button, Grid, Switch } from "@mui/material";
import { LayoutStyle } from "../layout/style";
import { ThemeContext } from "../../common/context/theme-context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/Button";
import { useDealerCompany } from "../../common/context/dealer-company-context";
import { useUser } from "../../common/context/user-context";
const UserSetting = () => {
  const classes = LayoutStyle();
  const { mode, toggleTheme } = useContext(ThemeContext);
  const {setDealerCompanyData} = useDealerCompany()
  const navigate = useNavigate();
  const {setUserData} = useUser()
  const handleLogout = async () => {
    localStorage.clear();
    setUserData([])
    setDealerCompanyData([])
    navigate("/");
  };
  return (
    <Box className={classes.gridUserSetting}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.userSetting}
        >
          <div className={classes.logoutDiv}>
            <span className={classes.headerAnalysisName}>User Setting</span>
            <Box>
              <ButtonComponent
                variant="contained"
                className={classes.fleetListNewButton}
                autoFocus
                sx={{ mt: 3, height: "50px" }}
                onClick={handleLogout}
              >
                Logout
              </ButtonComponent>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box>
            Theme:
            <Switch
              checked={mode === "dark"}
              onChange={toggleTheme}
              color="default"
              inputProps={{ "aria-label": "toggle theme" }}
            />
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box>
            <ButtonComponent
              variant="contained"
              className={classes.fleetListNewButton}
              autoFocus
              sx={{ mt: 3, height: "50px" }}
              onClick={handleLogout}
            >
              Logout
            </ButtonComponent>
          </Box>
        </Grid> */}
      </Grid>
    </Box>
  );
};
export default UserSetting;
