import React, { useState, createContext, useCallback, useContext } from "react";
import api from "../../configs/api";
import { showToast } from "../../components/Toast";

/**
 * Authentication context
 */
export const MakeContext = createContext();

/**
 * Authentication data provider
 */
export const MakeProvider = ({ children }) => {
  const [make, setMake] = useState([]);
  const [makeById, setMakeById] = useState(null);
  const [makeLoading, setMakeLoading] = useState(false);

  const getMakeApi = async (values) => {
    setMakeLoading(true);
    const response = await api.makeApi.getMake({
      limit: values?.limit ? values.limit : 10000,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setMakeLoading(false);
    }

    if (response?.data?.data) {
      setMake(response?.data?.data);
      setMakeLoading(false);
    } else {
      setMakeLoading(false);
    }

    return response.data;
  };
  const getMakeByIdApi = async (id) => {
    setMakeLoading(true);
    const response = await api.makeApi.getMakeByid(id);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setMakeLoading(false);
    }

    if (response?.data?.data) {
      setMakeById(response?.data?.data);
      setMakeLoading(false);
    } else {
      setMakeLoading(false);
    }

    return response.data;
  };
  const deleteMakeByIdApi = async (id) => {
    setMakeLoading(true);
    const response = await api.makeApi.deleteMakeByid(id);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      showToast("Something went wrong Try again", "error");
      setMakeLoading(false);
    }

    if (response?.data?.data) {
      getMakeApi();
      setMakeLoading(false);
    } else {
      setMakeLoading(false);
    }

    return response.data;
  };
  const createMakeApi = async (values) => {
    setMakeLoading(true);
    const response = await api.makeApi.createMake(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setMakeLoading(false);
    }

    if (response?.data?.data) {
      setMake(response?.data?.data);
      getMakeApi();
    } else {
      setMakeLoading(false);
    }

    return response.data;
  };
  const editMakeApi = async (values) => {
    setMakeLoading(true);
    const response = await api.makeApi.editMake(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setMakeLoading(false);
    }

    if (response?.data?.data) {
      getMakeApi();
      setMakeLoading(false);
    } else {
      setMakeLoading(false);
    }

    return response.data;
  };

  const contextValue = {
    make,
    setMake,
    makeLoading,
    setMakeLoading,
    setMakeById,
    makeById,
    getMakeApi: useCallback((request) => {
      getMakeApi(request);
    }, []),
    createMakeApi: useCallback((request) => {
      createMakeApi(request);
    }, []),
    editMakeApi: useCallback((request) => {
      editMakeApi(request);
    }, []),
    getMakeByIdApi: useCallback((request) => {
      getMakeByIdApi(request);
    }, []),
    deleteMakeApi: useCallback((request) => {
      deleteMakeByIdApi(request);
    }, []),
  };

  return (
    <MakeContext.Provider value={contextValue}>{children}</MakeContext.Provider>
  );
};

export const useMake = () => useContext(MakeContext);
