import React, { useState, createContext, useCallback, useContext } from "react";
// import { api.fundListApi } from "../api";
import api from "../../configs/api";

/**
 * Fund list context
 */
export const FundListContext = createContext();

/**
 * Fund list provider
 */
export const FundListProvider = ({ children }) => {
  const [fundList, setFundList] = useState([]);
  const [fundListById, setFundListById] = useState(null);
  const [loading, setLoading] = useState(false);

  const getFundListApi = async (values) => {
    setLoading(true);
    const response = await api.fundListApi.getFundList({
      limit: values?.limit ? values.limit : 100,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setFundList(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const getFundListByIdApi = async (id) => {
    setLoading(true);
    const response = await api.fundListApi.getFundListByid(id);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setFundListById(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const createFundListApi = async (values) => {
    setLoading(true);
    const response = await api.fundListApi.createFundList(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setFundList(response?.data?.data);
      getFundListApi({ companyId: values?.companyId, limit: 10000, offset: 0 });
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const editFundListApi = async (values) => {
    setLoading(true);
    const response = await api.fundListApi.editFundtList(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      getFundListApi({ companyId: values?.companyId, limit: 10000, offset: 0 });
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const deleteFundByIdApi = async (id) => {
    setLoading(true);
    const response = await api.fundListApi.deleteFundListByid(id);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data) {
      getFundListApi({ limit: 10000, offset: 0 });
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const contextValue = {
    fundList,
    setFundList,
    loading,
    setLoading,
    setFundListById,
    fundListById,
    getFundListApi: useCallback((request) => {
      getFundListApi(request);
    }, []),
    createFundListApi: useCallback((request) => {
      createFundListApi(request);
    }, []),
    editFundListApi: useCallback((request) => {
      editFundListApi(request);
    }, []),
    getFundListByIdApi: useCallback((request) => {
      getFundListByIdApi(request);
    }, []),
    deleteFundByIdApi: useCallback((request) => {
      deleteFundByIdApi(request);
    }, []),
  };

  return (
    <FundListContext.Provider value={contextValue}>
      {children}
    </FundListContext.Provider>
  );
};

export const useFundList = () => useContext(FundListContext);
