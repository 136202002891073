import React, { createContext, useCallback, useContext, useState } from "react";
import { showToast } from "../../components/Toast";
import api from "../../configs/api";

/**
 * Authentication context
 */
export const MaintananceContext = createContext();

/**
 * Authentication data provider
 */
export const MaintananceProvider = ({ children }) => {
  const [maintanance, setMaintanance] = useState();
  const [maintananceById, setMaintananceById] = useState(null);
  const [loading, setLoading] = useState(false);

  const getMaintananceApi = async (values) => {
    setLoading(true);
    const response = await api.maintainanceListApi.getMaintainanceList({
      limit: values?.limit ? values.limit : 10000,
      offset: values?.offset ? values.offset : 0,
      ...values,
      filter: {
        ...values?.filter,
      },
      sortColumn: "FleetNo",
      sortType: "desc",
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setMaintanance(response?.data?.data);
      setLoading(false);
    } else {
      setMaintanance([]);
      setLoading(false);
    }

    return response.data;
  };
  const getMaintananceByIdApi = async (id) => {
    setLoading(true);
    const response = await api.maintainanceListApi.getMaintainanceById(id);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setMaintananceById(response?.data?.data);
      setLoading(false);
    } else {
      setMaintananceById([]);
      setLoading(false);
    }

    return response.data;
  };
  const deleteMaintananceByIdApi = async (value) => {
    setLoading(true);
    const response = await api.maintainanceListApi.deleteMaintainanceList(
      value?.id
    );

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      getMaintananceApi({ companyId: value?.companyId });
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const createMainrainanceApi = async (values) => {
    setLoading(true);
    const response = await api.maintainanceListApi.addMaintainanceList(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setMaintanance(response?.data?.data);
      getMaintananceApi({ companyId: values?.companyId });
      setLoading(false);
      showToast("Maintanance Analysis created successfully!");
    } else {
      setMaintanance([]);
      showToast("Something went wrong Try again!");
      setLoading(false);
    }

    return response.data;
  };
  const editMaintainanceApi = async (values) => {
    setLoading(true);
    const response = await api.maintainanceListApi.updateMaintainanceList(
      values
    );
    if (response?.error) {
      setLoading(false);
    }
    if (response?.data?.data) {
      getMaintananceApi({
        fleetNo: values?.fleetNo,
        companyId: values?.companyId,
        dealerId: values?.dealerId,
      });
      setLoading(false);
      showToast("Maintanance Analysis updated successfully!");
    } else {
      showToast("Something went wrong Try again!");
      setLoading(false);
    }

    return response.data;
  };

  const contextValue = {
    maintanance,
    setMaintanance,
    loading,
    setLoading,
    setMaintananceById,
    maintananceById,
    getMaintananceApi: useCallback((request) => {
      getMaintananceApi(request);
    }, []),
    createMainrainanceApi: useCallback((request) => {
      createMainrainanceApi(request);
    }, []),
    editMaintainanceApi: useCallback((request) => {
      editMaintainanceApi(request);
    }, []),
    getMaintananceByIdApi: useCallback((request) => {
      getMaintananceByIdApi(request);
    }, []),
    deleteMaintananceByIdApi: useCallback((request) => {
      deleteMaintananceByIdApi(request);
    }, []),
  };

  return (
    <MaintananceContext.Provider value={contextValue}>
      {children}
    </MaintananceContext.Provider>
  );
};

export const useMaintainance = () => useContext(MaintananceContext);
