import React, { useState, createContext, useCallback, useContext } from "react";
import api from "../../configs/api";

/**
 * Fleet list context
 */
export const DriveTrainListContext = createContext();

/**
 * Fleet list provider
 */
export const DriveTrainListProvider = ({ children }) => {
  const [driveTrainList, setDriveTrainList] = useState();
  const [driveTrainListById, setDriveTrainListById] = useState(null);
  const [loading, setLoading] = useState(false);

  const getDriveTrainListApi = async (values) => {
    setLoading(true);
    const response = await api.driveTrainListApi.getDriveTrainList({
      limit: values?.limit ? values.limit : 100,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setDriveTrainList(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const getDriveTrainListByIdApi = async (id) => {
    setLoading(true);
    const response = await api.driveTrainListApi.getDriveTrainByid(id);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setDriveTrainListById(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const createDriveTrainListApi = async (values) => {
    setLoading(true);
    const response = await api.driveTrainListApi.createDriveTrainList(values);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setDriveTrainList(response?.data?.data);
      getDriveTrainListApi();
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const editDriveTrainListApi = async (values) => {
    setLoading(true);
    const response = await api.driveTrainListApi.editDriveTrainList(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      getDriveTrainListApi();
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const contextValue = {
    driveTrainList,
    setDriveTrainList,
    loading,
    setLoading,
    setDriveTrainListById,
    driveTrainListById,
    getDriveTrainListApi: useCallback((request) => {
      getDriveTrainListApi(request);
    }, []),
    createDriveTrainListApi: useCallback((request) => {
      createDriveTrainListApi(request);
    }, []),
    editDriveTrainListApi: useCallback((request) => {
      editDriveTrainListApi(request);
    }, []),
    getDriveTrainListByIdApi: useCallback((request) => {
      getDriveTrainListByIdApi(request);
    }, []),
    // deleteDealerByIdApi: useCallback((request) => {
    //   deleteDealerByIdApi(request);
    // }, []),
  };

  return (
    <DriveTrainListContext.Provider value={contextValue}>
      {children}
    </DriveTrainListContext.Provider>
  );
};

export const useDriveTrain = () => useContext(DriveTrainListContext);
