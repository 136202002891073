import { MenuItem, Select } from "@mui/material";
import React from "react";

export const HelpDeskSelect = ({
  option,
  className,
  value,
  menuProp,
  handleChange,
  width,
  variant,
  disable,
}) => {
  return (
    <Select
      size="small"
      variant={variant ? variant : "standard"}
      className={className}
      sx={{ width: width ? width : "148px !important" }}
      onChange={handleChange}
      value={value}
      MenuProps={menuProp}
      disabled={disable}
    >
      {option &&
        option?.length > 0 &&
        option?.map((item) => {
          return (
            <MenuItem key={item.value} value={item?.value}>
              {item.name}
            </MenuItem>
          );
        })}
    </Select>
  );
};
