export const yearConstants = [
  { id: 1, name: "year1", value: false, header: "Year 1" },
  { id: 2, name: "year2", value: false, header: "Year 2" },
  { id: 3, name: "year3", value: false, header: "Year 3" },
  { id: 4, name: "year4", value: false, header: "Year 4" },
  { id: 5, name: "year5", value: false, header: "Year 5" },
];

export const cycleYear = [
  {
    name: "Year1",
    value: 1,
  },
  {
    name: "Year2",
    value: 2,
  },
  {
    name: "Year3",
    value: 3,
  },
  {
    name: "Year4",
    value: 4,
  },
  {
    name: "Year5",
    value: 5,
  },
];

export const HelpDeskStatus = [
  {
    name: "Pending",
    value: "Pending",
  },
  {
    name: "Rejected",
    value: "Rejected",
  },
  {
    name: "Resolved",
    value: "Resolved",
  },
];
