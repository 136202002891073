import React, { useState, createContext, useCallback, useContext } from "react";
import { showToast } from "../../components/Toast";
import { useNavigate } from "react-router-dom";
import api from "../../configs/api";
import { typeConstant } from "../constant/commonFunction";
import { HelpDeskMessage } from "../constant/toastMessage";
import { localStorageItemNames } from "../constant/local-storage-item-names";

/**
 * Help desk context
 */
export const HelpDeskContext = createContext();

/**
 * Help desk provider
 */
export const HelpDeskProvider = ({ children }) => {
  const [helpdeskList, setHelpdeskList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const getHelpdeskListApi = async (values) => {
    setLoading(true);
    const userType = localStorage.getItem(localStorageItemNames.type);
    const response = await api.helpDeskListApi.getHelpDeskList({
      limit: values?.limit ? values.limit : 100,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setHelpdeskList(response?.data?.data);
      if (userType !== typeConstant.admin) {
        navigate("/helpDesk");
      } else {
        navigate("/userQueries");
      }
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const createHelpdeskListApi = async (values, userId) => {
    setLoading(true);
    const response = await api.helpDeskListApi.createHelpDesk(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setHelpdeskList(response?.data?.data);
      showToast(HelpDeskMessage.createSuccess);
      getHelpdeskListApi({
        filter: { userId: userId },
        limit: 10000,
        offset: 0,
      });
    } else {
      showToast(HelpDeskMessage.failedCreate);
      setLoading(false);
    }

    return response.data;
  };
  const editHelpdeskListApi = async (values) => {
    setLoading(true);
    const response = await api.helpDeskListApi.editHelpDesk(values);
    if (response?.error) {
      setLoading(false);
      showToast(HelpDeskMessage.failedUpdate);
    }

    if (response?.data?.data) {
      showToast(HelpDeskMessage.updateSuccess);
      getHelpdeskListApi({
        companyId: values?.companyId,
        limit: 10000,
        offset: 0,
      });
      setLoading(false);
    } else {
      showToast(HelpDeskMessage.failedUpdate);
      setLoading(false);
    }

    return response.data;
  };

  const deleteHelpdeskApi = async (id) => {
    setLoading(true);
    const response = await api.helpDeskListApi.deleteHelpDesk(id);

    if (response?.error) {
      showToast(HelpDeskMessage.failedDelete);
      setLoading(false);
    }

    if (response?.data) {
      getHelpdeskListApi({ limit: 10000, offset: 0 });
      showToast(HelpDeskMessage.deleteSuccess);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const contextValue = {
    helpdeskList,
    setHelpdeskList,
    loading,
    setLoading,
    getHelpDeskListApi: useCallback((request) => {
      getHelpdeskListApi(request);
    }, []),
    createHelpDeskListApi: useCallback((request, userId) => {
      createHelpdeskListApi(request, userId);
    }, []),
    editHelpDeskListApi: useCallback((request) => {
      editHelpdeskListApi(request);
    }, []),
    deleteHelpDeskApi: useCallback((request) => {
      deleteHelpdeskApi(request);
    }, []),
  };

  return (
    <HelpDeskContext.Provider value={contextValue}>
      {children}
    </HelpDeskContext.Provider>
  );
};

export const useHelpDesk = () => useContext(HelpDeskContext);
