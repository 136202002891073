import React, { useState, createContext, useCallback, useContext } from "react";
import api from "../../configs/api";

/**
 * Authentication context
 */
export const CompanyContext = createContext();

/**
 * Authentication data provider
 */
export const CompanyProvider = ({ children }) => {
  const [company, setCompany] = useState();
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [companyDefaultName, setCompanyDefaultName] = useState();
  const [companyHeader, setCompanyHeader] = useState();
  const [companyNameHeader, setCompanyNameHeader] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);

  const [dealerNameHeader, setDealerNameHeader] = useState(null);
  const getCompanyApi = async (values) => {
    setLoading(true);
    const response = await api.companyApi.getCompany({
      limit: values?.limit ? values.limit : 200,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });

    if (response?.error) {
      setLoading(false);
      return true;
    }

    if (response?.data?.data) {
      setCompany(response?.data?.data);
      setCompanyHeader(response?.data?.data);
      setLoading(false);
    }

    return response.data;
  };

  const getCompanyByid = async (id) => {
    const idList = id;
    setLoading(true);
    const promises = Object.values(idList).map(async (id) => {
      try {
        const response = await api.companyApi.getCompanyById(id);
        setLoading(false);
        return response;
      } catch (error) {
        // Handle errors if the API call fails for any ID
        console.error("Error fetching company for ID", id, ":", error);
        setLoading(false);
        throw error; // Rethrow the error to catch it later if needed
      }
    });
    const companies = await Promise.all(promises);
    setCompanyName(companies);
  };

  const addCompanyApi = async (values) => {
    setLoading(true);
    const response = await api.companyApi.addCompany({ ...values });

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      await getCompanyApi();
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const updateCompany = async (values) => {
    setLoading(true);
    const response = await api.companyApi.updateCompany({ ...values });

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      await getCompanyApi();
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const deleteCompany = async (id) => {
    setLoading(true);
    const response = await api.companyApi.deleteCompany(id);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      getCompanyApi();
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const getCompanyLogo = async (values) => {
    setLoading(true);
    const response = await api.companyApi.getCompanyById(values);

    if (response?.error) {
      setLoading(false);
      return true;
    }
    if (response?.data?.data) {
      setCompanyLogo(response?.data?.data);
      setLoading(false);
    }

    return response.data;
  };

  const contextValue = {
    company,
    loading,
    companyName,
    setLoading,
    setCompany,
    companyHeader,
    setCompanyHeader,
    companyNameHeader,
    setCompanyNameHeader,
    companyLogo,
    setCompanyLogo,
    companyDefaultName,
    setCompanyDefaultName,
    dealerNameHeader,
    setDealerNameHeader,
    getCompanyApi: useCallback((request) => {
      getCompanyApi(request);
    }, []),
    getCompanyByid: useCallback((request) => {
      getCompanyByid(request);
    }, []),
    addCompanyApi: useCallback((request) => {
      addCompanyApi(request);
    }, []),
    updateCompanyApi: useCallback((request) => {
      updateCompany(request);
    }, []),
    deleteCompanyApi: useCallback((request) => {
      deleteCompany(request);
    }, []),
    getCompanyLogo: useCallback((request) => {
      getCompanyLogo(request);
    }),
  };

  return (
    <CompanyContext.Provider value={contextValue}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => useContext(CompanyContext);
