import axios from "axios";
import { localStorageItemNames } from "../common/constant/local-storage-item-names";

// Get environment variables
const API_URL = process.env.REACT_APP_API_URL;
const IMGURL = process.env.REACT_APP_IMG_URL;

const api = axios.create({
  baseURL: `https://hma-analysis.com/adminApi/api/`,
});

// const api = axios.create({
//   baseURL: `https://localhost:44301/api/`,
// });

export const baseURL = `https://hma-analysis.com/adminApi/api/`;

export const _setTokenForAPI = async (token) => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  const _token = await localStorage.getItem("jwtToken");
  if (_token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${_token}`;
    return;
  }
};
_setTokenForAPI();
api.interceptors.request.use(
  (config) => ({
    ...config,
    validateStatus: (status) => status === 200 && status,
  }),
  (error) => Promise.reject(error)
);

const logoutUser = () => {
  localStorage.clear();
};

const logout = async () => {
  localStorage.clear();
};

api.interceptors.response.use(undefined, (error) => {
  console.log("error", error);
  if (error && error?.toJSON()?.message !== "Network Error") {
    if (error?.response?.status === 401) {
      logout();
    } else {
      console.log(error, "errorss");
    }
  }
  return Promise.reject(error);
});

export default api;
