import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Loading from "./components/Loading";
import { pageRoutes } from "./common/constant/routes/route";
import Layout from "../src/pages/layout/layout";
import { TruckLoader } from "./pages/dashboard/admin/truckLoader";

const Login = lazy(() => import("./pages/auth/Login"));
// const Error = lazy(() => import("./pages/404"));

function App() {
  return (
    <main className="AppRelative">
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<TruckLoader />}>
              <Login />
            </Suspense>
          }
        />
        <Route element={<Layout />}>
          {pageRoutes?.map((route) => (
            <Route
              path={route.path}
              element={
                <Suspense fallback={<TruckLoader />}>
                  <route.component />
                </Suspense>
              }
              key={route.path}
            />
          ))}
        </Route>
        {/* <Route path="*" element={<Error />} /> */}
      </Routes>
    </main>
  );
}

export default App;
