import React, { useState, createContext, useCallback, useContext } from "react";
import api from "../../configs/api";
import { showToast } from "../utils/Toast";
import { localStorageItemNames } from "../constant/local-storage-item-names";

/**
 * Authentication context
 */
export const UserContext = createContext();

/**
 * Authentication data provider
 */
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState([]);
  const [userData, setUserData] = useState([]);
  // const [typeId, setTypeId] = useState([])
  const [loading, setLoading] = useState(false);
  const [selectedNav, setSelectedNav] = useState("Dashboard");

  const getUser = async (values) => {
    try {
      setLoading(true);
      const response = await api.userApi.getUser({
        limit: values?.limit ? values.limit : 100,
        offset: values?.offset ? values.offset : 0,
        filter: {
          ...values?.filter,
        },
        sorting: {
          fieldName: "",
          direction: "",
        },
      });

      if (response?.data?.data) {
        setUser(response.data.data);
        setUserData(response.data.data);
        setLoading(false);

        // const user = response?.data?.data?.find((item) => item.id === parseInt(userId));

        // if (user) {
        //   setTypeId(user?.typeId)
        // } else {
        // }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getUserWithType = async (values) => {
    try {
      setLoading(true);
      const response = await api.userApi.getUsersType(values);

      if (response?.data?.data) {
        setUser(response.data.data);
        setUserData(response.data.data);
        setLoading(false);

        // const user = response?.data?.data?.find((item) => item.id === parseInt(userId));

        // if (user) {
        //   setTypeId(user?.typeId)
        // } else {
        // }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const createUser = async (request) => {
    // setLoading(true);
    try {
      setLoading(true);
      const response = await api.userApi.createUser(request);
      if (response.data.succeeded === true) {
        // alert(response.data.messages.length===0?"aaaa":"bbbb");/

        // if (userType === "dealer") {
        // getUser({ filter: { type: userType, typeId: userTypeId } });
        getUserWithType({
          userTypeId: localStorage.getItem(localStorageItemNames.userTypeId),
        });
        // } else {
        //   getUser();
        // }
        setLoading(false);
      } else {
        showToast(response.data.messages[0]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const updateUser = async (request) => {
    const userType = localStorage.getItem("type");
    const userTypeId = localStorage.getItem(localStorageItemNames.userID);
    // setLoading(true);
    try {
      setLoading(false);
      const response = await api.userApi.updateUser(request);
      if (response.data.succeeded === true) {
        // if (userType === "dealer") {
        // getUser({ filter: { type: userType, typeId: Number(userTypeId) } });
        getUserWithType({
          userTypeId: localStorage.getItem(localStorageItemNames.userTypeId),
        });
        // } else {
        //   getUser();
        // }
        setUserData(null);
        setLoading(false);

        return response;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteUser = async (id) => {
    // setLoading(true);
    try {
      // setLoading(true)
      const response = await api.userApi.deleteUser(id);
      if (response.data.succeeded === true) {
        // if (userType === "dealer") {
        //   getUser({ filter: { type: userType, typeId: userTypeId } });
        getUserWithType({
          userTypeId: localStorage.getItem(localStorageItemNames.userTypeId),
        });
        // } else {
        //   getUser();
        // }
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false)
    }
  };
  const getUserById = async (values) => {
    try {
      setLoading(true);
      const response = await api.userApi.getbyId(values);

      if (response?.data) {
        setUserData(response.data);
      } else {
        setUserData([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const contextValue = {
    user,
    loading,
    setUser,
    createUser,
    getUser,
    setUserData,
    updateUser,
    userData,
    selectedNav,
    setSelectedNav,
    // typeId,
    deleteUser: useCallback((id) => {
      deleteUser(id);
    }, []),
    getUserById: useCallback((request) => {
      getUserById(request);
    }, []),
    getUserWithType: useCallback((request) => {
      getUserWithType(request);
    }),
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
