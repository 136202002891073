import React, { useState, createContext, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../components/Toast";
import api from "../../configs/api";
import { TAB_CONTENT, TAB_VALUE } from "../utils/commonString";
import { localStorageItemNames } from "../constant/local-storage-item-names";

/**
 * Authentication context
 */
export const DealerContext = createContext();

/**
 * Authentication data provider
 */
export const DealerProvider = ({ children }) => {
  const [dealer, setDealer] = useState([]);
  const [dealerList, setDealerList] = useState();
  const [dealerById, setDealerById] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingFleetFile, setLoadingFleetFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fleetUpload, setFleetUpload] = useState(false);
  const [dealerId, setDealerId] = useState(null);
  const [companyDealerId, setCompanyDealerId] = useState(null);
  const [newFleetNo, setNewFleetNo] = useState(null);
  const navigation = useNavigate();

  const getDealerApi = async (values) => {
    setLoading(true);
    const response = await api.dealerApi.getDealer({
      limit: values?.limit ? values.limit : 10000,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "dealerName",
        direction: "asc",
      },
    });
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setDealer(response?.data?.data);
      setLoading(false);
      setFleetUpload(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const uploadCSVDealerApi = async (values, company) => {
    setLoadingFleetFile(true);
    const userTypeId = localStorage.getItem(localStorageItemNames.userTypeId);
    const response = await api.dealerApi.uploadCsv(values);
    if (response?.error) {
      setLoadingFleetFile(false);
    }
    if (response?.data?.succeeded) {
      setErrorMessage("empty");
      if (response?.data?.data?.calculatedFleets?.length == 0) {
        showToast(response?.data?.data?.message);
      } else if (response?.data?.data?.message) {
        showToast(response?.data?.data?.message);
      } else {
        showToast("Searching data from Government Website. Please wait.");
        setDealerId(response?.data?.data?.dealerId);
        setCompanyDealerId(response?.data?.data?.companyId);
        setDealerList(response?.data?.data);
        await api.dealerCompanyApi.getDealerCompanyList({
          companyId: response?.data?.data?.companyId,
          userTypeId: Number(userTypeId),
          dealerId: response?.data?.data?.dealerId,
        });
        setLoadingFleetFile(false);
      }
      if (response?.data?.data?.dealerId && response?.data?.data?.companyId) {
        setDealerId(response?.data?.data?.dealerId);
        setCompanyDealerId(response?.data?.data?.companyId);
      }
      setFleetUpload(true);
      setLoadingFleetFile(false);
    } else if (response?.data?.messages) {
      showToast(response.data.messages[0]);
      setFleetUpload(false);
    } else {
      setDealerList([]);
      setFleetUpload(false);
      setLoadingFleetFile(false);
    }

    return response.data;
  };
  const updateFleetApi = async (cavUpload, createPayload) => {
    const response = await api.dealerApi.uploadCsv({ data: [cavUpload] });
    const userId = localStorage.getItem(localStorageItemNames.userID);
    if (response?.error) {
      setLoadingFleetFile(false);
    }
    if (response?.data?.data?.calculatedFleets?.length > 0) {
      let fleetNo = response?.data?.data?.calculatedFleets[0]?.fleet?.fleetNo;
      let id = response?.data?.data?.calculatedFleets[0]?.fleet?.id;
      let fleetUpdatePayload = {
        ...createPayload,
        fleetNo: fleetNo,
        id: id,
      };
      let fleetUpdate = await api.fleetListApi.updateFleetList(
        fleetUpdatePayload
      );
      await api.dealerApi.singleFleetUpdate({
        fleetNo: fleetNo,
        company: cavUpload?.company,
        dealer: cavUpload?.dealer,
        fuelId: createPayload?.fuelId,
        make: cavUpload?.make,
        model: cavUpload?.model,
        mpg: createPayload?.mpg,
        stateCode: cavUpload?.stateCode,
        totalMiles: createPayload?.totalMiles,
        typeId: createPayload?.typeId,
        vin: createPayload?.vin,
        year: cavUpload?.year,
        userId: Number(userId),
      });
      setNewFleetNo(fleetNo);
      if (fleetUpdate?.data?.succeeded) {
        navigation("/new-fleetlist", {
          state: {
            tabValue: TAB_VALUE.FLEET_LIST,
            selectedContent: TAB_CONTENT.FLEET_LIST,
            data: { ...createPayload, fleetNo: fleetNo, fleetId: id },
          },
        });
        showToast("Fleet Created Successfully!");
      } else {
        showToast("Failed to Create Fleet!");
      }
    } else if (response?.data?.messages) {
      showToast(response.data.messages[0]);
    } else {
    }

    return response.data;
  };
  const multipleFleetUpdateDealerApi = async (values, company) => {
    setLoading(true);
    const response = await api.dealerApi.multipleFleetUpdate([...values]);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const singleFleetUpdateDealerApi = async (values, company) => {
    setLoading(true);
    const response = await api.dealerApi.singleFleetUpdate(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const manualCalculationApi = async (values, company) => {
    setLoading(true);
    const response = await api.dealerApi.manualCalculationUpdate(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const multipleManualCalculationApi = async (values, company) => {
    setLoading(true);
    const response = await api.dealerApi.multipleManualCalculationUpdate(
      values
    );
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const contextValue = {
    dealer,
    setDealer,
    loading,
    setLoading,
    setDealerById,
    dealerById,
    dealerList,
    setDealerList,
    errorMessage,
    fleetUpload,
    setFleetUpload,
    setErrorMessage,
    dealerId,
    newFleetNo,
    setNewFleetNo,
    setDealerId,
    companyDealerId,
    setCompanyDealerId,
    loadingFleetFile,
    setLoadingFleetFile,
    getDealerApi: useCallback((request) => {
      getDealerApi(request);
    }, []),
    updateFleetApi: useCallback((cavUpload, createPayload) => {
      updateFleetApi(cavUpload, createPayload);
    }, []),
    uploadCSVDealerApi: useCallback((request) => {
      uploadCSVDealerApi(request);
    }, []),
    multipleFleetUpdate: useCallback((request) => {
      multipleFleetUpdateDealerApi(request);
    }, []),
    singleFleetUpdateDealerApi: useCallback((request) => {
      singleFleetUpdateDealerApi(request);
    }, []),
    manualCalculationApi: useCallback((request) => {
      manualCalculationApi(request);
    }, []),
    multipleManualCalculationApi: useCallback((request) => {
      multipleManualCalculationApi(request);
    }),
  };

  return (
    <DealerContext.Provider value={contextValue}>
      {children}
    </DealerContext.Provider>
  );
};

export const useDealer = () => useContext(DealerContext);
