export const getDefaultColDef = () => {
  return {
    flex: 1,
    minWidth: 100,
    filter: "agTextColumnFilter",
    menuTabs: ["filterMenuTab"],
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };
};

export const addComma = (number) => {
  return number
    ? number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "0";
};

export const getCurrencyValue = (currencyValue) => {
  var numberValue = 0;
  if (currencyValue) {
    numberValue = Number(currencyValue).toFixed(2);
  }

  const formattedNumber = addComma(numberValue);
  return "$ " + formattedNumber;
};

export const YearConstant = {
  year1: "year1",
  year2: "year2",
  year3: "year3",
  year4: "year4",
  year5: "year5",
};
