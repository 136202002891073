import {
  Analytics,
  Apartment,
  DirectionsCarFilled,
  HelpOutline,
  ManageAccounts,
  ShowChartRounded,
} from "@mui/icons-material";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalAtmRoundedIcon from "@mui/icons-material/LocalAtmRounded";
import LocalGasStationOutlinedIcon from "@mui/icons-material/LocalGasStationOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";

export const SidebarMenu = [
  { path: "dashboard", name: "Dashboard", icon: <DashboardOutlinedIcon /> },
  {
    name: "Fleet",
    icon: <LocalShippingOutlinedIcon />,
    item: [
      {
        path: "/fleet-list",
        name: "Fleet List",
        icon: <ListAltIcon />,
      },
      {
        path: "/replacement-schedule",
        name: "Replacement Schedule",
        icon: <CalendarMonthIcon />,
      },
      {
        path: "/maintenance-analysis",
        name: "Maintenance Analysis",
        icon: <CarRepairIcon />,
      },
      {
        path: "/fuel-analysis",
        name: "Fuel Analysis",
        icon: <AnalyticsOutlinedIcon />,
      },
      {
        path: "/funding-new-vehicle",
        name: "Funding/New Vehicle",
        icon: <LocalAtmRoundedIcon />,
      },
    ],
  },
  {
    path: "analysis-results",
    name: "Analysis Results",
    icon: <SignalCellularAltOutlinedIcon />,
  },
  {
    path: "fuel-price",
    name: "Fuel Price",
    icon: <LocalGasStationOutlinedIcon />,
  },
  {
    path: "fuel-economy",
    name: "Fuel Economy",
    icon: <ShowChartRounded />,
  },
  {
    name: "Admin",
    icon: <Person2OutlinedIcon />,
    item: [
      {
        path: "/manage-company",
        name: "Manage Company",
        icon: <Apartment />,
      },
      {
        path: "/manage-user",
        name: "Manage User",
        icon: <ManageAccounts />,
      },
      {
        path: "/manage-vehicle",
        name: "Manage Vehicle",
        icon: <DirectionsCarFilled />,
      },
    ],
  },
  {
    path: "analysisData",
    name: "Analysis Data",
    icon: <Analytics />,
  },
  {
    path: "userQueries",
    name: "User Queries",
    icon: <HelpOutline />,
  },
];

export const navUserItems = [
  { path: "dashboard", name: "Dashboard", icon: <DashboardOutlinedIcon /> },
  {
    name: "Fleet",
    icon: <LocalShippingOutlinedIcon />,
    item: [
      {
        path: "/fleet-list",
        name: "Fleet List",
        icon: <ListAltIcon />,
      },
      {
        path: "/replacement-schedule",
        name: "Replacement Schedule",
        icon: <CalendarMonthIcon />,
      },
      {
        path: "/maintenance-analysis",
        name: "Maintenance Analysis",
        icon: <CarRepairIcon />,
      },
      {
        path: "/fuel-analysis",
        name: "Fuel Analysis",
        icon: <AnalyticsOutlinedIcon />,
      },
      {
        path: "/funding-new-vehicle",
        name: "Funding/New Vehicle",
        icon: <LocalAtmRoundedIcon />,
      },
    ],
  },
  {
    path: "analysis-results",
    name: "Analysis Results",
    icon: <SignalCellularAltOutlinedIcon />,
  },
  {
    path: "fuel-price",
    name: "Fuel Price",
    icon: <LocalGasStationOutlinedIcon />,
  },
  {
    path: "fuel-economy",
    name: "Fuel Economy",
    icon: <ShowChartRounded />,
  },
  {
    name: "Admin",
    icon: <Person2OutlinedIcon />,
    item: [
      {
        path: "/manage-company",
        name: "Manage Company",
        icon: <Apartment />,
      },
      {
        path: "/manage-user",
        name: "Manage User",
        icon: <ManageAccounts />,
      },
      {
        path: "/manage-vehicle",
        name: "Manage Vehicle",
        icon: <DirectionsCarFilled />,
      },
    ],
  },
  {
    path: "analysisData",
    name: "Analysis Data",
    icon: <Analytics />,
  },
  {
    path: "helpDesk",
    name: "Help Desk",
    icon: <HelpOutline />,
  },
];
