import React, { useState, createContext, useCallback, useContext } from "react";
// import { CompanyAPi } from "../api";
// import { useLoader } from "../hooks";
import api from "../../configs/api";

/**
 * Authentication context
 */
export const DealerCompanyContext = createContext();

/**
 * Authentication data provider
 */
export const DealerCompanyProvider = ({ children }) => {
  const [dealerCompanyData, setDealerCompanyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDealerCompany, setLoadingDealerCompany] = useState(false);

  const getDealerCompanyApi = async (values) => {
    setLoading(true);
    const response = await api.dealerCompanyApi.getDealerCompanyList({
      ...values,
      limit: values?.limit ? values.limit : 10000,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
        deleted: false,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      setDealerCompanyData([]);
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      // setLoading(false)
    }

    if (response?.data?.data?.length > 0) {
      //   response.data.data?.map(async(item) =>
      //     getCompanyByid(item.companyId)
      // )
      setDealerCompanyData(response?.data?.data);

      setLoading(false);
    } else if (response?.data?.length > 0) {
      setDealerCompanyData(response?.data);

      setLoading(false);
    } else {
      setLoading(false);
      setDealerCompanyData([]);
    }

    return response.data;
  };

  const addDealerCompanyApi = async (values) => {
    setLoading(true);
    const response = await api.dealerCompanyApi.createDealerCompany({
      ...values,
    });

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      // await getDealerCompanyApi();
      // setLoading(false)
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const getDealerCompanyListApi = async (values) => {
    setLoading(true);
    const response = await api.dealerCompanyApi.getDealerCompany({
      ...values,
      limit: values?.limit ? values.limit : 1000,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
      dealerId: values?.dealerId ? values?.dealerId : null,
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      // setLoading(false)
    }

    if (response?.data?.data) {
      // response.data.data?.map(async(item) =>
      //   getCompanyByid(item.companyId)
      // )
      setDealerCompanyData(response?.data?.data);
      // setCompany(response?.data?.data)
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const getDealerCompanyV2 = async (values) => {
    setLoading(true);
    const response = await api.dealerCompanyApi.getDealerCompanyList({
      limit: values?.limit ? values.limit : 10000,
      offset: values?.offset ? values.offset : 0,
      ...values,
      filter: {
        ...values?.filter,
        deleted: false,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      // setLoading(false)
    }

    if (response?.data) {
      //   response.data.data?.map(async(item) =>
      //     getCompanyByid(item.companyId)
      // )
      setDealerCompanyData(response?.data);

      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const deleteDealerCompanyListApi = async (values) => {
    setLoadingDealerCompany(true);
    const response = await api.dealerCompanyApi.deleteDealerCompany(
      Number(values?.id)
    );
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      // setLoading(false)
    }

    if (response) {
      // response.data.data?.map(async(item) =>
      //   getCompanyByid(item.companyId)
      // )
      await getDealerCompanyV2({ dealerId: values?.dealerId });
      // setCompany(response?.data?.data)
      setLoadingDealerCompany(false);
    } else {
      setLoadingDealerCompany(false);
    }

    return response.data;
  };
  const editDealerApi = async (values) => {
    setLoading(true);
    const response = await api.dealerCompanyApi.editDealerCompany(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      getDealerCompanyApi();
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const contextValue = {
    loading,
    loadingDealerCompany,
    dealerCompanyData,
    setLoading,
    setDealerCompanyData,
    getDealerCompanyApi: useCallback((request) => {
      getDealerCompanyApi(request);
    }, []),
    addDealerCompanyApi: useCallback((request) => {
      addDealerCompanyApi(request);
    }, []),
    getDealerCompanyListApi: useCallback((request) => {
      getDealerCompanyListApi(request);
    }, []),
    deleteCompanyDealer: useCallback((request) => {
      deleteDealerCompanyListApi(request);
    }, []),
    editDealerCompany: useCallback((request) => {
      editDealerApi(request);
    }, []),
  };

  return (
    <DealerCompanyContext.Provider value={contextValue}>
      {children}
    </DealerCompanyContext.Provider>
  );
};

export const useDealerCompany = () => useContext(DealerCompanyContext);
