import React, { useMemo } from "react";
import { getDefaultColDef } from "../utils/commonFuntions";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { useTheme } from "../hooks/theme";

const CommonAgGrid = ({
  setSelectedRows,
  rowData,
  columnData,
  onRowSelected,
  onCellClicked,
  onRowSelectionModelChange,
  uploadCsv,
  onCellValueChanged,
  analysisReport,
  fuelEconomy,
  onSelectionChanged,
  gridAnalysisResult,
  powerbiReportChart,
  fleetGrid,
  frameworkComponents,
}) => {
  const defaultColDef = useMemo(() => getDefaultColDef(), []);
  const { themeData, mode } = useTheme();
  return (
    <>
      <AgGridReact
        className={
          mode === "light" &&
          !analysisReport &&
          !uploadCsv &&
          !fuelEconomy &&
          !gridAnalysisResult &&
          !powerbiReportChart &&
          !fleetGrid
            ? "ag-theme-quartz"
            : uploadCsv
            ? "ag-theme-quartz csvUpload"
            : fuelEconomy
            ? "ag-theme-quartz ag-grid-fuelEconomy"
            : analysisReport
            ? "ag-theme-quartz ag-gridReport"
            : gridAnalysisResult
            ? "ag-theme-quartz ag-gridResult"
            : fleetGrid
            ? "ag-theme-quartz ag-fleetGrid"
            : powerbiReportChart
            ? "ag-theme-quartz ag-report-class"
            : "ag-theme-quartz-dark"
        }
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowSelection="multiple"
        suppressRowClickSelection
        pagination={true}
        rowData={rowData}
        defaultColDef={defaultColDef}
        checkboxSelection
        columnDefs={columnData}
        onSelectionChanged={onSelectionChanged}
        onRowSelected={onRowSelected}
        onCellClicked={onCellClicked}
        onCellValueChanged={onCellValueChanged}
        frameworkComponents={frameworkComponents}
      />
    </>
  );
};

export default CommonAgGrid;
