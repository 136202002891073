import { makeStyles } from "@mui/styles";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { useTheme } from "../../common/hooks/theme";

const drawerWidth = 240;
export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const LayoutStyle = makeStyles((theme) => {
  return {
    appbarmain: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    appbarlogo: {
      display: "flex",
      alignItems: "center",
    },
    agGridContent: {
      height: `calc(100vh - 220px)`,
      fontSize: "12px !important",
    },
    headerNameText: {
      fontSize: "14px !important",
      fontWeight: 700,
    },
    mainContent: {
      padding: "0px 20px 0 20px",
      height: "100%",
    },
    addEditGrid: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    fleetListNewButton: {
      fontSize: "12px !important",
      background: "#002C5F",
      color: "white",
      padding: "8px 25px",
      borderRadius: "5px",
    },
    bottomNav: {
      backgroundColor: "#002C5F !important",
    },
    bottomDarkNav: {
      backgroundColor: "#4E4E4E !important",
    },
    footerNav: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "white",
      fontSize: "12px",
      height: "100%",
    },
    mainContentFleet: {
      height: `auto`,
      marginTop: "60px",
      marginLeft: "240px",
      padding: 20,
      background: "white",
      marginBottom: "60px",
    },
    fleetListInformation: {
      marginTop: 15,
      marginBottom: 50,
      padding: "24px !important",
    },
    headerFleet: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 5,
      marginLeft: "22px !important",
      background: theme?.palette?.primary?.main,
      color: "white",
      fontWeight: 600,
    },
    textGeneralInfo: {
      fontWeight: "700 !important",
      textTransform: "capitalize !important",
    },
    addModel: {
      "& .MuiDrawer-paper": {
        height: "calc(100% - 114px)",
        marginTop: "64px",
        marginBottom: "50px",
      },
    },
    dailogTitle: {
      justifyContent: "center",
      display: "flex",
      fontWeight: "500 !important",
    },
    addModelForm: {
      display: "flex",
      flexDirection: "column !important",
      gap: "25px",
      margin: 15,
      width: "30vw",
      marginY: 1,
      paddingX: 2,
    },
    disFlexCenter: {
      display: "flex",
      justifyContent: "center !important",
      alignItems: "center",
    },
    addModelFormField: {
      display: "flex",
      flexDirection: "column  !important",
      gap: "5px",
    },
    fontSize12: {
      fontSize: "12px !important",
      fontWeight: "600 !important",
    },
    addFleetListButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#645f5f !important",
      background: "#002C5F !important",
      height: "20px",
    },
    mainContent: {
      height: `calc(100vh - 121px)`,
      padding: 10,
    },
    gridContantFleetList: {
      height: `calc(100vh - 240px)`,
      marginTop: "10px",
      fontSize: "12px !important",
    },
    gridContantDealer: {
      height: `calc(100vh - 240px) !important`,
      marginTop: "1px",
      fontSize: "12px !important",
    },
    headerAnalysisReport: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "12px !important",
      padding: 5,
    },
    headerAnalysisName: {
      fontWeight: 600,
    },
    reportButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#002C5F !important",
      background: "#002C5F !important",
    },
    tabText: {
      "&.MuiTab-root": {
        textTransform: "capitalize",
        fontWeight: 600,
        fontSize: "12px",
      },
    },
    gridUserSetting: {
      height: `calc(100vh - 240px)`,
      marginTop: "1px",
      fontSize: "12px !important",
      padding: "20px !important",
    },
    logoutDiv: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    subMenuHover: {
      "&:hover": {
        color: "white !important",
      },
    },
    requireFieldValue: {
      color: "red",
      fontSize: "14px !important",
      textTransform: "capitalize",
    },
    requireFieldValueLast: {
      color: "red",
      fontSize: "14px !important",
      textTransform: "capitalize",
      marginRight: 5,
    },
    columnRequired: {
      color: "red",
      fontSize: "12px !important",
      marginRight: 10,
    },
    fetchingMakeModel: {
      width: "20px !important",
      height: "20px !important",
    },
    estimatedValueButton: {
      justifyContent: "end",
      display: "flex",
      alignItems: "center",
      background: "#002C5F !important",
      color: "white !important",
    },
    urlDivFuelPrice: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "14px",
    },
    gridContantEconomy: {
      height: `calc(100vh - 240px)`,
      margin: "-22px !important",
      marginTop: "3px !important",
      fontSize: "12px !important",
    },
    headerSaveButton: {
      marginLeft: "10px",
      color: "#002C5F !important",
      fontWeight: "800 !important",
      backgroundColor: "#fff !important  ",
    },
    deleteCancelButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      fontSize: "12px !important",
      borderColor: "#002C5F !important",
      background: "#002C5F !important",
    },
    deleteOkButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#002C5F !important",
      background: "#002C5F !important",
      fontSize: "12px !important",
      "&:hover": {
        background: "red !important",
        borderColor: "red !important",
      },
    },
    compnayFormField: {
      display: "flex",
      flexDirection: "column  !important",
      gap: "5px",
    },
    companyForm: {
      display: "flex",
      flexDirection: "column !important",
      gap: "25px",
      margin: 15,
      width: "30vw",
      marginY: 1,
      paddingX: 2,
    },
    loginButton: {
      width: "100%",
      background: "#1B3E66",
      color: "white",
      fontSize: "14px",
      fontWeight: 500,
      textTransform: "capitalize",
      padding: "10px",
      "&:hover": {
        background: "white",
        color: "black",
      },
    },
    userNameContent: {
      fontSize: "14px !important",
      fontWeight: "500 !important",
      display: "flex",
      alignItems: "center",
    },
    notediv: {
      display: "flex",
      justifyContent: "end",
      float: "right",
      width: "100%",
      alignItems: "center",
    },
    redbox: {
      height: "15px !important",
      width: "15px !important",
      background: "#F77479 !important",
      border: "1px solid gray !important",
    },
    yellowbox: {
      height: "15px !important",
      width: "15px !important",
      background: "#F4F5A2 !important",
      border: "1px solid gray !important",
      marginLeft: "10px",
    },
    noteContain: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dailogTitleInput: {
      m: 0,
      p: 2,
      justifyContent: "center",
      fontSize: 14,
      marginRight: "10px",
      color: "#000",
    },
    dailogTitleInputSetAll: {
      m: 0,
      p: 2,
      justifyContent: "center",
      fontSize: 14,
      width: "15%",
      marginRight: "10px",
      color: "#000",
    },
    actionDialog: {
      display: "flex",
      width: "50%",
      margin: "0px auto",
      alignItems: "center",
      fontSize: "14px !important",
    },
    actionButtonDialog: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "12px !important",
      margin: 10,
    },
    addActionButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#002C5F !important",
      background: "#002C5F !important",
      height: "30px",
    },
    actionDialogCommon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "14px !important",
      marginTop: "8px !important",
    },
    textinput: {
      "& .MuiFilledInput-underline::before": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      "& .MuiFilledInput-underline::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      "& .MuiInputBase-root-MuiInput-root-MuiSelect-root": {
        padding: "0px !important",
        fontSize: "12px !important",
        color: "#18333A !important",
      },
      "& .MuiInput-root": {
        padding: "0px !important",
        fontSize: "12px !important",
        color: "#18333A !important",
      },
      "& .MuiInput-underline::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      "& .MuiSelect-standard::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      fontSize: "12px !important",
      color: "#18333A !important",
    },
    textinputDailog: {
      "& .MuiFilledInput-underline::before": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      "& .MuiFilledInput-underline::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      "& .MuiInputBase-root-MuiInput-root-MuiSelect-root": {
        padding: "0px !important",
        fontSize: "12px !important",
        color: "#18333A !important",
      },
      "& .MuiInput-root": {
        padding: "0px !important",
        fontSize: "12px !important",
        color: "#18333A !important",
      },
      "& .MuiInput-underline::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      "& .MuiSelect-standard::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      fontSize: "12px !important",
      color: "#18333A !important",
      width: 500,
    },
    helpDeskGridContant: {
      height: `calc(100vh - 240px)`,
      marginTop: "1px",
      fontSize: "12px !important",
    },
    helpTextBox: {
      width: "90%",
    },
    PreviewButton: {
      cursor: "pointer",
      background: "#18333A !important",
      color: "white !important",
      marginRight: "5px !important",
    },
    EditImageButton: {
      cursor: "pointer",
      background: "#18333A !important",
      color: "white !important",
    },
    chatLeft: {
      textAlign: "left",
    },
    chatRight: {
      textAlign: "right",
    },
    headerUserQuery: {
      fontSize: "14px !important",
      fontWeight: 700,
      color: "#18333A",
      padding: 10,
    },
    commentTextBox: {
      width: "80%",
      border: "1px solid black !important",
      borderRadius: 10,
      marginTop: 10,
      marginRight: "5px !important",
    },
  };
});

export const AdminPageStyle = makeStyles((theme) => {
  return {
    container: {
      backgroundImage: `url("/bgimg.jpg")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "100vh",
      position: "relative",
    },

    backgroundVideo: {
      position: "absolute",
      width: "100% !important",
      objectFit: "cover",
      transitionTimingFunction: "ease",
    },

    formContainer: {
      padding: "20px",
      position: "absolute",
      background: "red",
      width: "500px",
      height: "250px",
      float: "right",
      margin: "10px",
      [theme.breakpoints.only("sm")]: {
        "@media screen and (min-width: 520px)": {
          width: "250px ",
          height: "220px ",
          background: "green",
        },
      },
      [theme.breakpoints.only("xs")]: {
        "@media screen and (min-width: 320px)": {
          width: "150px !important",
          height: "120px !important",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        },
      },
    },

    mainContent: {
      height: `calc(100vh - 170px)`,
      marginTop: "60px",
      marginLeft: "240px",
      padding: 20,
      background: "white",
      marginBottom: "60px",
    },
    mainAnalysisContent: {
      margin: "5px",
      padding: 20,
    },
    fleetPaper: {
      margin: 10,
      padding: 10,
      border: "1px solid lightgray",
      borderRadius: 5,
    },
    fleetPaperMainDiv: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.only("sm")]: {
        "@media screen and (max-width: 768px)": {
          overflowX: "scroll",
        },
      },
    },
    textFleetList: {
      fontSize: 14,
      fontWeight: "bold",
      marginLeft: 12,
    },
    fleetListSecDiv: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      width: "100%",
      margin: 2,

      ".MuiButtonBase-root": {
        width: "25%",
      },
    },
    gridAnalysisItem: {
      "@media screen and (max-width: 1191px)": {
        paddingLeft: "5px !important",
      },
    },
    divMt10: {
      marginTop: 5,
      "@media screen and (max-width: 1191px)": {
        marginTop: "0px !important",
      },
    },
    bgGraycolor: {
      color: "white !important",
      backgroundColor: "#18333A !important",
      boxShadow: "1px 0px 5px 1px gray",
      padding: "0px !important",
      marginRight: "11px !important",
      width: "25%",
    },

    fleetListButton: {
      fontSize: 12,
      display: "flex",
      justifyContent: "left",
    },
    fleetListButtonText: {
      fontSize: 12,
      textTransform: "capitalize",
      textAlign: "left",
      fontWeight: "500 !important",
    },
    fleetListInformation: {
      margin: 10,
    },
    textGeneralInfo: {
      fontSize: 14,
      fontWeight: "700 !important",
      textTransform: "capitalize !important",
    },
    textGeneralInfoResult: {
      fontSize: 14,
      fontWeight: "700 !important",
      textTransform: "capitalize !important",
      borderBottom: "1px solid gray",
      paddingTop: "0px !important",
    },
    textinput: {
      "& .MuiFilledInput-underline::before": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      "& .MuiFilledInput-underline::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      "& .MuiInputBase-root-MuiInput-root-MuiSelect-root": {
        padding: "0px !important",
        fontSize: "12px !important",
        color: "#18333A !important",
      },
      "& .MuiInput-root": {
        padding: "0px !important",
        fontSize: "12px !important",
        color: "#18333A !important",
      },
      "& .MuiInput-underline::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      "& .MuiSelect-standard::after": {
        borderBottom: "2px solid rgb(156 191 46) !important",
      },
      fontSize: "12px !important",
      color: "#18333A !important",
    },
    fleetListGenInfoTtext: {
      width: "160px",
      marginRight: 20,
      fontSize: "12px !important",
      color: "#18333A !important",
      fontWeight: "500 !important",
    },
    fleetListMileageText: {
      width: "200px",
      fontSize: "12px !important",
      color: "gray !important",
      fontWeight: "500 !important",
    },
    fleetListTotalMileageText: {
      width: "200px",
      fontSize: "12px !important",
      color: "96B82C !important",
      fontWeight: "500 !important",
    },
    fleetListGenInfoInputText: {
      width: "200px",
      fontSize: "12px !important",
      color: "#18333A !important",
      "@media screen and (max-width: 1356px)": {
        fontSize: "11px !important",
        marginRight: "0px !important",
      },
    },
    fleetListGenInfoDiv: {
      display: "flex",
      alignItems: "center",
    },
    fleetListDefultText: {
      width: "120px",
      fontSize: "12px !important",
      color: "gray !important",
      fontWeight: "500 !important",
    },
    mainContentFleet: {
      height: `auto`,
      marginTop: "60px",
      marginLeft: "240px",
      padding: 20,
      background: "white",
      marginBottom: "60px",
    },
    btn1: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      "&:focus": {
        background: "red !important",
      },
    },
    btn2: {
      background: "white !important",
      color: "#18333A",
      padding: "5px",
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      width: "25px",
      "&:focus": {
        background: "red !important",
      },
    },
    btn3: {
      background: "#96B82C",
      padding: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderTopRightRadius: "4px !important",
      borderBottomRightRadius: "4px !important",
      width: "calc(100% - 25px)",
      "&:focus": {
        background: "red !important",
      },
    },
    focusVisible: {
      background: "red !important",
      "&:focus": {
        background: "red !important",
      },
    },
    saveButtonFleet: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerFleet: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 5,
      marginLeft: "17px !important",
      background: "#18333A",
      marginTop: "20px !important",
      color: "white",
      fontWeight: 600,
    },
    reportButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#18333A !important",
      background: "#18333A !important",
    },
    headerAnalysisReport: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "12px !important",
    },
    headerAnalysisName: {
      fontWeight: 600,
      padding: "5px 30px",
      background: "#002C5F",
      color: "white",
    },
    gridContantReport: {
      width: "100%",
      marginLeft: 5,
      fontSize: "12px !important",
      "@media screen and (max-width: 1385px)": {
        height: `calc(100vh - 470px)`,
      },
    },
    analysisDivMix: {
      padding: 10,
      fontSize: 12,
      fontWeight: 600,
    },
    headerButtonNext: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    analysisSaveBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerSaveButton: {
      marginLeft: "10px",
      color: "#18333A !important",
      fontWeight: "800 !important",
      backgroundColor: "#fff !important  ",
    },
    totalSavingText: {
      backgroundColor: "#1461B8 !important",
      borderRadius: "4px",
      padding: "7px 5px",
      fontSize: "14px",
      fontWeight: 600,
      color: "white",
    },
    estimatedValueBox: {
      justifyContent: "end",
      display: "flex",
      marginTop: 10,
    },
    estimatedValueButton: {
      justifyContent: "end",
      display: "flex",
      alignItems: "center",
      background: "#949300 !important",
      color: "white !important",
    },
    aggridAnalysisReport: {
      width: "100%",
    },
    analysisListGenInfoTtext: {
      width: "460px",
      marginRight: 20,
      fontSize: "12px !important",
      color: "#18333A !important",
      fontWeight: "500 !important",
      "@media screen and (max-width: 1356px)": {
        fontSize: "11px !important",
        marginRight: "0px !important",
      },
    },
    reportAnalysisButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#002C5F !important",
      background: "#002C5F !important",
      padding: "2px 7px !important",
      fontSize: "10px !important",
    },
    reportAnalysisSaveButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#002C5F !important",
      background: "#002C5F !important",
      padding: "5px 8px !important",
      fontSize: "12px !important",
    },
    analysisResultTextDiv: {
      display: "flex",
      alignItems: "center",
    },
    accordinAnalysisResult: {
      height: "170px !important",
      "@media screen and (max-width: 1450px)": {
        height: "157px !important",
        padding: "0px 3px 25px !important",
      },
      "@media screen and (max-width: 1200px)": {
        height: "150px !important",
        padding: "0px 3px 16px !important",
      },
      "@media screen and (max-width: 995px)": {
        height: "170px !important",
        padding: "0px 5px 16px !important",
        marginTop: "0px",
      },
    },
    analysisTotalSavingButton: {
      display: "flex",
      alignItems: "center",
    },
    exportPowerBIButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "white !important",
      borderColor: "#002C5F !important",
      background: "#002C5F !important",
      padding: "3px 15px !important",
      fontSize: "10px !important",
    },
    loadingCircular: {
      width: "20px",
      height: "20px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    fuelPriceSign: {
      marginRight: 2,
    },
  };
});

export const ReportStyle = makeStyles((theme) => {
  return {
    reportInnerDiv: {
      padding: "5px",
      backgroundColor: "#002C5F !important",
    },
    reportDivThree: {
      borderRadius: "0px !important",
      backgroundColor: "#18333a",
      margin: "0px",
      width: "100%",
    },
    reportCardThree: {
      borderRadius: "0px !important",
      backgroundColor: "#002C5F !important",
      border: "1px solid #002C5F",
      margin: "0px !important",
      height: "100px !important",
      maxHeight: "150px !important",
    },
    reportInnerDivTitle: {
      color: "white",
      fontSize: 14,
      padding: 5,
    },
    reportInnerDivTotal: {
      color: "white",
      fontSize: 24,
      padding: 5,
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
    },
    reportInnerDivIcon: {
      color: "white",
      fontSize: 12,
      padding: 4,
      paddingTop: "0px",
      paddingBottom: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    reportInnerDivSecTotal: {
      color: "white",
      fontSize: 34,
      padding: 5,
      paddingTop: 5,
      paddingBottom: 5,
      textAlign: "center",
    },
    reportInnerDivSecTitle: {
      color: "white",
      fontSize: 18,
      padding: 4,
      paddingTop: 10,
      paddingBottom: 17,
      textAlign: "center",
    },
    reportMainDiv: {
      backgroundColor: "#fff",
      padding: 5,
      paddingLeft: 5,
    },
    reportHeader: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: "0px",
      marginTop: "0px",
      padding: "5px",
      fontSize: 24,
      color: "white",
      backgroundColor: "#002C5F !important",
    },
    reportHeaderTitle: {
      display: "flex",
      justifyContent: "space-between !important",
      alignItems: "center",
      width: "100%",
    },
    exportPowerBIButton: {
      marginRight: "5px !important",
      fontWeight: "bold !important",
      color: "#000 !important",
      borderColor: "#fff !important",
      background: "white !important",
      height: "30px",
    },
    reportCardBorder: {
      borderRadius: "0px !important",
      border: "1px solid #002C5F !important",
      backgroundColor: "transparent !important",
      height: "100%",
    },
    cardChartHeader: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "0px",
      marginTop: "0px",
      padding: "5px",
      fontSize: 16,
      color: "#002C5F",
    },
    marginTopOne: {
      marginTop: "0px !important",
    },
    reportCardSec: {
      borderRadius: "0px !important",
      backgroundColor: "#002C5F !important",
      margin: "0px !important",
    },
    logoutButton: {
      fontWeight: "bold !important",
      fontSize: "12px !important",
    },
    userSetting: {
      padding: "20px !important",
    },
  };
});
