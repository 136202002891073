import api from "./apiConfig";

const AuthApi = "Authentication";
const auth = {
  userLogin: (data) => api.post(`${AuthApi}/LoginWithOTP`, data),
  userLogout: (data) => api.post(`${AuthApi}/Logout`, data),
  GetAllLanguage: () => api.get(`${AuthApi}/DropDown/GetAllLanguage`),
};

const replacementScheduleList = "ReplacementSchedule";
const replacementScheduleListApi = {
  getReplacementList: (data) =>
    api.post(`${replacementScheduleList}/ReplacementScheduleList`, data),
  getReplacementListById: (data) =>
    api.post(`${replacementScheduleList}`, data),
  addReplacementList: (data) =>
    api.post(`${replacementScheduleList}/Create`, data),
  updateReplacementtList: (data) =>
    api.post(`${replacementScheduleList}/Update`, data),
  deleteReplacementListById: (data) =>
    api.delete(`${replacementScheduleList}`, data),
};

const maintainanceList = `Maintenance`;
const maintainanceListApi = {
  getMaintainanceList: (data) =>
    api.post(`${maintainanceList}/MaintenanceList`, data),
  getMaintainanceById: (data) => api.post(`${maintainanceList}`, data),
  addMaintainanceList: (data) => api.post(`${maintainanceList}/Create`, data),
  updateMaintainanceList: (data) =>
    api.post(`${maintainanceList}/Update`, data),
  deleteMaintainanceList: (data) => api.delete(`${maintainanceList}`, data),
};
const fuelAnalysisList = "FuelAnalysis";
const fuelAnalysisListApi = {
  getFuelAnalysisList: (data) => api.post(`${fuelAnalysisList}/List`, data),
  getFuelAnalysisById: (data) => api.post(`${fuelAnalysisList}`, data),
  addFuelAnalysisList: (data) => api.post(`${fuelAnalysisList}/Create`, data),
  updateFuelAnalysisList: (data) =>
    api.post(`${fuelAnalysisList}/Update`, data),
  getListFuelAnalysisList: (data) =>
    api.post(`${fuelAnalysisList}/FuelAnalysisList`, data),
  deleteFuelAnalysisList: (data) => api.delete(`${fuelAnalysisList}`, data),
};

const fleetList = "Fleet";
const fleetListApi = {
  getFleetList: (data) => api.post(`${fleetList}/FleetList`, data),
  getFleetListByName: (data) => api.post(`${fleetList}/FleetList`, data),
  getFleetListById: (data) => api.get(`${fleetList}/data`, data),
  deleteFleetById: (data) => api.delete(`${fleetList}?id=${data}`, data),
  addFleetList: (data) => api.post(`${fleetList}/Create`, data),
  updateFleetList: (data) => api.post(`${fleetList}/Update`, data),
  fleetListVehicleInfo: (data) =>
    api.post(`${fleetList}/FundingNewVehicleList`, data),
  multipleFleet: (data) => api.post(`${fleetList}/UpdateMultiple`, data),
  multipleFleetDelete: (data) => api.post(`${fleetList}/DeleteMultiple`, data),
};
const dealer = "Dealer";
const dealerApi = {
  getDealer: (data) => api.post(`${dealer}/List`, data),
  getListDealer: (data) => api.post(`${dealer}/ListDealer`, data),
  getDealerById: (data) => api.get(`${dealer}/${data}`, data),
  deleteDealerById: (data) => api.delete(`${dealer}?id=${data}`, data),
  createDealer: (data) => api.post(`${dealer}/Create`, data),
  editDealer: (data) => api.post(`${dealer}/Update`, data),
  uploadCsv: (data) => api.post(`${dealer}/UploadCSVData`, data),
  multipleFleetUpdate: (data) => api.post(`${dealer}/MultipleUpdate`, data),
  singleFleetUpdate: (data) => api.post(`${dealer}/UpdateCalculation`, data),
  manualCalculationUpdate: (data) =>
    api.post(`${dealer}/ManualCalculation`, data),
  multipleManualCalculationUpdate: (data) =>
    api.post(`${dealer}/MultipleManualCalculationUpdate`, data),
};

const company = "Company";
const companyApi = {
  getCompany: (data) => api.post(`${company}/List`, data),
  getCompanyById: (data) => api.get(`${company}/${data}`),
  addCompany: (data) => api.post(`${company}/Create`, data),
  updateCompany: (data) => api.post(`${company}/Update`, data),
  deleteCompany: (data) => api.delete(`${company}?id=${data}`),
};

const fuelList = "Fuel";
const fuelListApi = {
  getFuelList: (data) => api.post(`${fuelList}/List`, data),
  getFuelListByid: (data) => api.get(`${fuelList}/${data}`, data),
  createFuelList: (data) => api.post(`${fuelList}/Create`, data),
  editFueltList: (data) => api.post(`${fuelList}/Update`, data),
  deleteFuelListByid: (data) => api.delete(`${fuelList}?id=${data}`, data),
};

const driveTrainList = "DriveTrain";
const driveTrainListApi = {
  getDriveTrainList: (data) => api.post(`${driveTrainList}/List`, data),
  getDriveTrainByid: (data) => api.post(`${driveTrainList}/${data}`, data),
  createDriveTrainList: (data) => api.post(`${driveTrainList}/Create`, data),
  editDriveTrainList: (data) => api.post(`${driveTrainList}/Update`, data),
};

const typeList = "Type";
const typeListApi = {
  geTypeList: (data) => api.post(`${typeList}/List`, data),
  getTypeListByid: (data) => api.get(`${typeList}/${data}`, data),
  createTypeList: (data) => api.post(`${typeList}/Create`, data),
  editTypeList: (data) => api.post(`${typeList}/Update`, data),
};

const stateList = "State";
const stateListApi = {
  getStateList: (data) => api.post(`${stateList}/List`, data),
  getStateListByid: (data) => api.post(`${stateList}/${data}`, data),
  createStateList: (data) => api.post(`${stateList}/Create`, data),
  editStateList: (data) => api.post(`${stateList}/Update`, data),
};

const year = "Year";
const yearApi = {
  getYear: (data) => api.post(`${year}/List`, data),
  getYearById: (data) => api.post(`${year}/${data}`, data),
  deleteYearByid: (id) => api.delete(`${year}?id=${id}`),
  createYear: (data) => api.post(`${year}/Create`, data),
  updateYear: (data) => api.post(`${year}/Update`, data),
};

const make = "Make";
const makeApi = {
  getMake: (data) => api.post(`${make}/List`, data),
  getMakeByid: (data) => api.get(`${make}/${data}`, data),
  deleteMakeByid: (id) => api.delete(`${make}?id=${id}`),
  createMake: (data) => api.post(`${make}/Create`, data),
  editMake: (data) => api.post(`${make}/Update`, data),
};
const model = "Model";
const ModelApi = {
  getModel: (data) => api.post(`${model}/List`, data),
  getModelByid: (data) => api.post(`${model}/${data}`, data),
  getMakeById: (data) => api.get(`${make}/${data}`, data),
  deleteModelByid: (id) => api.delete(`${model}?id=${id}`),
  createModel: (data) => api.post(`${model}/Create`, data),
  editModel: (data) => api.post(`${model}/Update`, data),
  modelList: (data) => api.post(`${model}/ModelListByMakeAndYearId`, data),
  closetMatchModel: (data) => api.post(`${model}/GetClosetMachModule`, data),
};
const analysisResultList = "AnalysisResults";
const analysisResultListApi = {
  getAnalysisResultList: (data) => api.post(`${analysisResultList}/List`, data),
  getAnalysisResultById: (data) =>
    api.get(`${analysisResultList}/${data}`, data),
  addAnalysisResultList: (data) =>
    api.post(`${analysisResultList}/Create`, data),
  updateAnalysisResultList: (data) =>
    api.post(`${analysisResultList}/Update`, data),
  deleteAnalysisResultList: (data) => api.delete(`${analysisResultList}`, data),
};

const fundList = "FundingType";
const fundListApi = {
  getFundList: (data) => api.post(`${fundList}/List`, data),
  getFundListById: (data) => api.get(`${fundList}/${data}`, data),
  addFundList: (data) => api.post(`${fundList}/Create`, data),
  updateFundtList: (data) => api.post(`${fundList}/Update`, data),
  deleteFundListById: (data) => api.delete(`${fundList}`, data),
};

const newVehicle = "NewVehicle";
const newVehicleApi = {
  getNewVehicle: (data) => api.post(`${newVehicle}/NewVehicleList`, data),
  getNewVehicleById: (data) => api.get(`${newVehicle}/${data}`, data),
  deleteNewVehicleById: (data) => api.delete(`${newVehicle}`, data),
  createNewVehicle: (data) => api.post(`${newVehicle}/Create`, data),
  editNewVehicle: (data) => api.post(`${newVehicle}/Update`, data),
  multipleNewVehicle: (data) => api.post(`${newVehicle}/UpdateMultiple`, data),
};

const dealerCompany = "DealerCompany";
const dealerCompanyApi = {
  getDealerCompanyList: (data) =>
    api.post(`${dealerCompany}/DealerCompanyList`, data),
  createDealerCompany: (data) => api.post(`${dealerCompany}/Create`, data),
  getDealerCompany: (data) =>
    api.post(`${dealerCompany}/DealerCompanyList`, data),
  deleteDealerCompany: (data) =>
    api.delete(`${dealerCompany}?id=${data}`, data),
  editDealerCompany: (data) => api.post(`${dealerCompany}/Update`, data),
};

const manageUser = "Users";
const userApi = {
  getUser: (request) => api.post(`${manageUser}/List`, request),
  getUsersType: (request) =>
    api.post(`${manageUser}/UserListByUserType`, request),

  createUser: (request) => api.post(`${manageUser}/Create`, request),
  updateUser: (request) => api.post(`${manageUser}/Update`, request),
  deleteUser: (request) => api.delete(`${manageUser}?id=${request}`),
  getbyId: (request) => api.get(`${manageUser}/${request}`, request),
};

const fuelEconomyData = "FuelEconomy";
const fuelEconomyApi = {
  getFuelEconomyList: (request) => api.post(`${fuelEconomyData}/List`, request),
  createFuelEconomy: (request) =>
    api.post(`${fuelEconomyData}/Create`, request),
  updateFuelEconomy: (request) =>
    api.post(`${fuelEconomyData}/Update`, request),
  deleteFuelEconomy: (request) =>
    api.delete(`${fuelEconomyData}?id=${request}`),
  getFuelEconomyById: (request) =>
    api.get(`${fuelEconomyData}/${request}`, request),
};

const userType = "UserType";
const userTypeApi = {
  getUserTypeList: (request) => api.post(`${userType}/List`, request),
  createUserType: (request) => api.post(`${userType}/Create`, request),
  updateUserType: (request) => api.post(`${userType}/Update`, request),
  deleteUserType: (request) => api.delete(`${userType}/id=${request}`),
  getUserTypeById: (request) => api.get(`${userType}/${request}`, request),
  getUserTypeSpecificId: (request) =>
    api.post(`${userType}/UserTypeList`, request),
};
const helpDesk = "HelpDesk";
const helpDeskListApi = {
  getHelpDeskList: (request) => api.post(`${helpDesk}/List`, request),
  createHelpDesk: (request) => api.postForm(`${helpDesk}/Create`, request),
  editHelpDesk: (request) => api.postForm(`${helpDesk}/Update`, request),
  deleteHelpDesk: (id) => api.delete(`${helpDesk}?id=${id}`),
};

const comments = "Comments";
const CommentsApi = {
  getCommentsList: (request) => api.post(`${comments}/List`, request),
  createComments: (request) => api.post(`${comments}/Create`, request),
  editComments: (request) => api.post(`${comments}/Update`, request),
};
export default {
  auth,
  replacementScheduleListApi,
  maintainanceListApi,
  fuelAnalysisListApi,
  dealerApi,
  fleetListApi,
  companyApi,
  fuelListApi,
  driveTrainListApi,
  typeListApi,
  stateListApi,
  yearApi,
  makeApi,
  ModelApi,
  analysisResultListApi,
  fundListApi,
  newVehicleApi,
  dealerCompanyApi,
  userApi,
  fuelEconomyApi,
  userTypeApi,
  helpDeskListApi,
  CommentsApi,
};
