import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useModel } from "../../../common/context/model-context";
import { LayoutStyle } from "../../layout/style";

export const SelectedModels = (props) => {
  const dataValue = props;
  const classes = LayoutStyle();
  const {
    closetModel,
    newRowData,
    setNewRowData,
    selectedRow,
    setSelectedRow,
    modelLoading,
    setIsClosetModel,
    selectedModel,
    setSelectedModel,
  } = useModel();
  const [open, setOpen] = useState(false);

  const [newModelData, setNewModelData] = useState(null);
  const [selectIndex, setSelectIndex] = useState([]);

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Handles changes in the model selection dialog and opens the dialog
   * @param {object} event - The event that triggered the change
   * @param {object} value - The currently selected model
   * @param {array} items - The list of models to choose from
   */
  /******  86d24ade-52b0-4196-9a89-b8a03a764940  *******/
  const handleSelectChange = (event, value, items) => {
    setSelectedModel(value);
    setNewModelData(items);
    setOpen(true);
  };

  let ids = selectedRow?.map((items) => items.id);

  useEffect(() => {}, [selectIndex]);
  const handleOk = () => {
    let data = [...selectIndex];
    const updatedModels = newRowData?.map((item) => {
      if (ids.includes(item?.id)) {
        const matchingClosetModel = closetModel.find(
          (model) => model.id === item.id
        );
        if (matchingClosetModel) {
          const modelExists = selectedRow.some(
            (selectedItem) =>
              selectedItem.id === matchingClosetModel.id &&
              matchingClosetModel.closetModuleList.some(
                (closetModule) => selectedModel?.model === closetModule.model
              )
          );
          if (modelExists) {
            return {
              ...item,
              selectedModel: selectedModel,
              originalModel: item?.model,
            };
          } else {
            return {
              ...item,
            };
          }
        }
      } else if (newModelData?.id == item?.id) {
        const updatedItem = {
          ...item,
          selectedModel: selectedModel,
          originalModel: item?.model,
        };
        return updatedItem;
      } else {
        return item;
      }
    });
    setSelectIndex(data);
    setNewRowData(updatedModels);
    setOpen(false);
    setSelectedRow([]);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (closetModel) {
      closetModel?.map((item) => {
        if (item?.closetModuleList?.length > 0) {
          item["selectedModelRow"] = true;
        } else {
          item["selectedModelRow"] = false;
        }
      });
      setIsClosetModel(closetModel);
    }
  }, [closetModel, newRowData]);
  return (
    <div className="SelectedModelClass">
      {modelLoading ? (
        <span>
          <CircularProgress
            color="inherit"
            className={classes.fetchingMakeModel}
          />
        </span>
      ) : (
        <FormControl
          fullWidth
          variant="standard"
          size="small"
          alignItems="center"
          key={dataValue?.data?.model}
        >
          {closetModel &&
            closetModel?.map((items) => {
              if (items?.id === dataValue.data.id) {
                return (
                  <Autocomplete
                    disablePortal={false}
                    id="combo-box-demo"
                    options={items?.closetModuleList}
                    fullWidth
                    key={items?.id}
                    loading={items?.closetModuleList}
                    loadingText={"Fetching...."}
                    getOptionLabel={(option) => option.model}
                    renderOption={(props, option) => {
                      return (
                        <li
                          style={{ position: "relative" }}
                          {...props}
                          key={items?.id}
                        >
                          {option.model}
                        </li>
                      );
                    }}
                    defaultValue={dataValue?.data?.selectedModel}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                    onChange={(event, value) =>
                      handleSelectChange(event, value, items)
                    }
                  />
                );
              }
              return null;
            })}
        </FormControl>
      )}

      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>Confirm Selection</DialogTitle>
        <DialogContent>
          Are you sure you want to select this model?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} className={classes.fleetListNewButton}>
            Cancel
          </Button>
          <Button onClick={handleOk} className={classes.closeModelOkBtn}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
