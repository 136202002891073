import {
  AccountCircle,
  ChevronLeft,
  ChevronRight,
  Email,
  ExpandLess,
  ExpandMore,
  Menu as MenuIcon,
  MoreVert as MoreIcon,
  Notifications as NotificationsIcon,
} from "@mui/icons-material";
import {
  Autocomplete,
  Badge,
  Box,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { CompanyDealer } from "../../common/constant/CompanyDealer";
import { navUserItems, SidebarMenu } from "../../common/constant/sidebarMenus";
import { useCompany } from "../../common/context/company-context";
import { useDealerCompany } from "../../common/context/dealer-company-context";
import { useDealer } from "../../common/context/dealer-context";
import { useUser } from "../../common/context/user-context";
import { AppBar, DrawerHeader, LayoutStyle, Main } from "./style";
import { localStorageItemNames } from "../../common/constant/local-storage-item-names";
import { typeConstant } from "../../common/constant/commonFunction";

const drawerWidth = 240;

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const classes = LayoutStyle();
  const [open, setOpen] = useState(true);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const navigate = useNavigate();
  const { setSelectedNav, selectedNav, getUserById, setUserData } = useUser();
  const [companyDefaultValue, setCompanyDefaultValue] = useState(null);
  const { company, setCompanyNameHeader, setDealerNameHeader } = useCompany();
  const { getDealerCompanyListApi, dealerCompanyData, setDealerCompanyData } =
    useDealerCompany();
  const { dealer, dealerId, companyDealerId } = useDealer();
  const [userName, setUserName] = useState("");
  const [userType, setUserType] = useState(null);

  React.useEffect(() => {
    const userTypeId = localStorage.getItem(localStorageItemNames.userTypeId);
    const userNameData = localStorage.getItem(localStorageItemNames.name);
    if (dealer)
      getDealerCompanyListApi({
        dealerId: CompanyDealer.dealer,
        userTypeId: Number(userTypeId),
      });
    getUserById(userTypeId);
    setUserName(userNameData);
  }, [company]);

  const [companiesOption, setCompaniesOption] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [dealerDefaultValue, setDealerDefaultValue] = useState(null);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleSubMenuToggle = (index) => {
    setOpenSubMenu(openSubMenu === index ? null : index);
  };

  const onMenuItemClick = (nav, index) => {
    let slectItem;
    if (userType === typeConstant.admin) {
      slectItem = SidebarMenu?.filter((item) => item?.name === nav?.name);
    } else {
      slectItem = navUserItems?.filter((item) => item?.name === nav?.name);
    }
    console.log("slectItem", slectItem);
    setSelectedItemIndex(index);
    if (slectItem?.length > 0) {
      setSelectedNav(slectItem[0]?.name);
    }
    navigate(nav?.path);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleCompanyChange = (e, value) => {
    setCompanyNameHeader(value);
    setCompanyDefaultValue(value);
  };
  useEffect(() => {
    if (companyDealerId) {
      const userTypeId = localStorage.getItem(localStorageItemNames.userTypeId);
      if (dealer)
        getDealerCompanyListApi({
          dealerId: CompanyDealer.dealer,
          userTypeId: Number(userTypeId),
          companyId: Number(companyDealerId),
        });
    }
  }, [companyDealerId]);
  useEffect(() => {
    const userType = localStorage.getItem(localStorageItemNames.type);
    setUserType(userType);
    let dealerFilter =
      dealerCompanyData?.filter((item) => item.id == dealerId) || [];
    if (dealerFilter.length > 0) {
      setDealerDefaultValue(dealerFilter[0]);
      setDealerNameHeader(dealerFilter[0]);
      setCompaniesOption(dealerFilter);
      let companyId = dealerFilter?.find((item) => item.id == companyDealerId);
      setCompanyDefaultValue(dealerFilter[0]);
      setCompanyNameHeader(dealerFilter[0]);
    } else if (companyDealerId) {
      let filters = dealerCompanyData?.filter(
        (item) => item.dealerId == CompanyDealer.dealer
      );
      let dealerFilter1 =
        dealerCompanyData?.filter(
          (item) => item.companyId == companyDealerId
        ) || [];
      setDealerDefaultValue(dealerFilter1[0]);
      setDealerNameHeader(dealerFilter1[0]);
      setCompaniesOption(filters);
      setCompanyDefaultValue(dealerFilter1[0]);
      setCompanyNameHeader(dealerFilter1[0]);
    } else {
      let filteredCompany = dealerCompanyData?.map((item) => ({
        companyName: item?.companyName,
        companyId: item?.companyId,
      }));

      setDealerDefaultValue(filteredCompany[0]);
      if (!companyDefaultValue && filteredCompany.length > 0) {
        setCompanyDefaultValue(filteredCompany[0]);
        setCompanyNameHeader(filteredCompany[0]);
        setCompaniesOption(filteredCompany);
      } else return;
    }
  }, [dealerCompanyData, dealerId, companyDealerId, dealer]);

  const onSubItemClick = (nav, index, array) => {
    setSelectedItemIndex(null);
    const slectItem = array?.filter((item) => item?.name === nav?.name);
    if (slectItem?.length > 0) {
      setSelectedNav(slectItem[0]?.name);
    }
    navigate(nav?.path);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleProfile = () => {
    setAnchorEl(null);
    navigate("/profile");
  };
  const handleLogout = async () => {
    localStorage.clear();
    setUserData([]);
    setDealerCompanyData([]);
    navigate("/");
  };
  const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfile}>Profile</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails">
          <Badge badgeContent={4} color="error">
            <Email />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Box className={classes.appbarmain}>
            <Box className={classes.userNameContent}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <div variant="h6" noWrap component="div" textAlign={"center"}>
                Name : {userName}
              </div>
            </Box>
            <Box className={classes.appbarlogo}>
              <img
                src="/white-logo.png"
                alt="whitelogo"
                height={15}
                width={100}
              />
            </Box>
            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                variant="outlined"
                sx={{
                  width: "160px",
                  marginRight: "10px",
                  ".MuiSvgIcon-root, .MuiInputBase-root ": { color: "#ffffff" },
                  ".MuiInputBase-root": {
                    fontSize: "12px",
                    "&:before": { borderBottom: "1px solid #fff" },
                    "&:after": { borderBottom: "2px solid #fff" },
                  },
                }}
                loadingText="Fetching..."
                options={companiesOption}
                getOptionLabel={(option) =>
                  `${option.companyName} FIN: ${option.companyFinNumber}`
                }
                renderOption={(props, option) => (
                  <li {...props}>
                    <div>
                      <strong>{option.companyName}</strong>
                      <br />
                      <span> {option.companyFinNumber}</span>
                    </div>
                  </li>
                )}
                value={companyDefaultValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Company"
                    sx={{
                      ".MuiFormLabel-root": {
                        color: "white !important",
                        fontSize: 12,
                      },
                    }}
                  />
                )}
                onChange={handleCompanyChange}
              />
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List style={{ marginBottom: 70 }}>
          {userType === typeConstant.admin
            ? SidebarMenu?.map((nav, index) => (
                <React.Fragment key={index}>
                  {nav?.item ? (
                    <>
                      <ListItemButton
                        onClick={() => handleSubMenuToggle(index)}
                        sx={{
                          "&:hover": {
                            bgcolor: "#1565c0 !important",
                            color: "white !important",
                          },
                          "&:hover .MuiListItemIcon-root": {
                            color: "white !important",
                          },
                        }}
                      >
                        <ListItemIcon style={{ padding: "5px" }}>
                          {nav?.icon}
                        </ListItemIcon>
                        <span
                          className={classes.subMenuHover}
                          style={{
                            width: "100%",
                            color: "black",
                          }}
                        >
                          {nav?.name}
                        </span>
                        {openSubMenu === index ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={openSubMenu === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {nav?.item?.map((subNavItem, subIndex) => (
                            <ListItem
                              key={subIndex}
                              disablePadding
                              onClick={() =>
                                onSubItemClick(subNavItem, subIndex, nav?.item)
                              }
                              sx={{
                                "&:hover": {
                                  bgcolor: "#1565c0 !important",
                                  color: "white !important",
                                },
                                "&:hover .MuiListItemIcon-root": {
                                  color: "white !important",
                                },
                                ".MuiListItemIcon-root": {
                                  color:
                                    selectedNav === subNavItem.name
                                      ? "white !important"
                                      : "black !important",
                                },
                              }}
                              style={{
                                backgroundColor:
                                  selectedNav === subNavItem.name
                                    ? "#002C5F"
                                    : "transparent",
                                color:
                                  selectedNav === subNavItem.name
                                    ? "white"
                                    : "black",
                              }}
                            >
                              <ListItemButton
                                sx={{ pl: 4, fontWeight: "bold" }}
                              >
                                <ListItemIcon>{subNavItem?.icon}</ListItemIcon>
                                <span
                                  style={{ fontWeight: 500, width: "100%" }}
                                >
                                  {subNavItem?.name}
                                </span>
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </>
                  ) : (
                    <ListItem
                      disablePadding
                      onClick={() => onMenuItemClick(nav, index)}
                      sx={{
                        "&:hover": {
                          bgcolor: "#1565c0 !important",
                          color: "white !important",
                        },
                        "&:hover .MuiListItemIcon-root": {
                          color: "white !important",
                        },
                        ".MuiListItemIcon-root": {
                          color:
                            selectedNav === nav?.name &&
                            selectedItemIndex === index
                              ? "white"
                              : "black",
                        },
                      }}
                      style={{
                        backgroundColor:
                          selectedNav === nav?.name &&
                          selectedItemIndex === index
                            ? "#002C5F"
                            : "transparent",
                        color:
                          selectedNav === nav?.name &&
                          selectedItemIndex === index
                            ? "white"
                            : "black",
                      }}
                    >
                      <ListItemButton sx={{ fontWeight: "bold" }}>
                        <ListItemIcon style={{ padding: "5px" }}>
                          {nav?.icon}
                        </ListItemIcon>
                        <span style={{ fontWeight: 500 }}>{nav?.name}</span>
                      </ListItemButton>
                    </ListItem>
                  )}
                </React.Fragment>
              ))
            : navUserItems?.map((nav, index) => (
                <React.Fragment key={index}>
                  {nav?.item ? (
                    <>
                      <ListItemButton
                        onClick={() => handleSubMenuToggle(index)}
                        sx={{
                          "&:hover": {
                            bgcolor: "#1565c0 !important",
                            color: "white !important",
                          },
                          "&:hover .MuiListItemIcon-root": {
                            color: "white !important",
                          },
                        }}
                      >
                        <ListItemIcon style={{ padding: "5px" }}>
                          {nav?.icon}
                        </ListItemIcon>
                        <span
                          className={classes.subMenuHover}
                          style={{
                            width: "100%",
                            color: "black",
                          }}
                        >
                          {nav?.name}
                        </span>
                        {openSubMenu === index ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={openSubMenu === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {nav?.item?.map((subNavItem, subIndex) => (
                            <ListItem
                              key={subIndex}
                              disablePadding
                              onClick={() =>
                                onSubItemClick(subNavItem, subIndex, nav?.item)
                              }
                              sx={{
                                "&:hover": {
                                  bgcolor: "#1565c0 !important",
                                  color: "white !important",
                                },
                                "&:hover .MuiListItemIcon-root": {
                                  color: "white !important",
                                },
                                ".MuiListItemIcon-root": {
                                  color:
                                    selectedNav === subNavItem.name
                                      ? "white !important"
                                      : "black !important",
                                },
                              }}
                              style={{
                                backgroundColor:
                                  selectedNav === subNavItem.name
                                    ? "#002C5F"
                                    : "transparent",
                                color:
                                  selectedNav === subNavItem.name
                                    ? "white"
                                    : "black",
                              }}
                            >
                              <ListItemButton
                                sx={{ pl: 4, fontWeight: "bold" }}
                              >
                                <ListItemIcon>{subNavItem?.icon}</ListItemIcon>
                                <span
                                  style={{ fontWeight: 500, width: "100%" }}
                                >
                                  {subNavItem?.name}
                                </span>
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </>
                  ) : (
                    <ListItem
                      disablePadding
                      onClick={() => onMenuItemClick(nav, index)}
                      sx={{
                        "&:hover": {
                          bgcolor: "#1565c0 !important",
                          color: "white !important",
                        },
                        "&:hover .MuiListItemIcon-root": {
                          color: "white !important",
                        },
                        ".MuiListItemIcon-root": {
                          color:
                            selectedNav === nav?.name &&
                            selectedItemIndex === index
                              ? "white"
                              : "black",
                        },
                      }}
                      style={{
                        backgroundColor:
                          selectedNav === nav?.name &&
                          selectedItemIndex === index
                            ? "#002C5F"
                            : "transparent",
                        color:
                          selectedNav === nav?.name &&
                          selectedItemIndex === index
                            ? "white"
                            : "black",
                      }}
                    >
                      <ListItemButton sx={{ fontWeight: "bold" }}>
                        <ListItemIcon style={{ padding: "5px" }}>
                          {nav?.icon}
                        </ListItemIcon>
                        <span style={{ fontWeight: 500 }}>{nav?.name}</span>
                      </ListItemButton>
                    </ListItem>
                  )}
                </React.Fragment>
              ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
      {renderMenu}
      {renderMobileMenu}
    </Box>
  );
}
