import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import api from "../../configs/api";
import { _setTokenForAPI } from "../../configs/apiConfig";
import { useNavigate } from "react-router-dom";
import { localStorageItemNames } from "../constant/local-storage-item-names";
import { showToast } from "../../components/Toast";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const url = window.location.pathname;
  /**
   * use history hooks
   */
  const history = useNavigate();

  /**
   * user data state management
   */
  const [user, setUser] = useState();
  const [userLoggin, setUserLoggin] = useState();
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(false);

  /**
   * logged in state
   */
  const [isLoggedIn, setLoggedIn] = useState(
    localStorage.getItem(localStorageItemNames.user_token) ? true : false
  );
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authData, setAuthData] = useState(null);

  const loginUser = async (payload) => {
    setLoading(true);
    const response = await api.auth.userLogin({
      otp: "",
      userName: payload?.userName,
      password: payload?.password,
      email: payload?.email,
      isOTP: false,
    });
    if (response?.data?.data && response.data.succeeded === true) {
      const dealerId = await api.dealerApi.getDealer({
        limit:10,
        offset:0,
        filter:{deleted:false,dealerName:"Hyundai"},
        sorting:{}
      }) 
      if(dealerId?.data?.data?.length > 0){
        localStorage.setItem("dealerId", dealerId?.data?.data[0]?.id);
      }
      setUserId(response?.data?.data?.userId);
      localStorage.setItem(
        localStorageItemNames.userTypeName,
        response?.data?.data?.userTypeName
      );
      localStorage.setItem(
        localStorageItemNames.user_token,
        response?.data?.data?.token
      );
      localStorage.setItem(
        localStorageItemNames.userTypeId,
        response?.data?.data?.userTypeId
      );
      localStorage.setItem(
        localStorageItemNames.userID,
        response?.data?.data?.userId
      );
      localStorage.setItem("name", response?.data?.data?.userName);
      localStorage.setItem("type", response?.data?.data?.type);
      localStorage.setItem("typeId", response?.data?.data?.typeId);
      setLoggedIn(true);
      setUserLoggin(response?.data?.data);
      // if(user.data){
      // history('/Dashboard')
      // }
      //   addMessage({ message: response?.data?.msg, type: "success" });
      history("/dashboard");
      setLoading(false);
    } else {
      showToast(response?.data?.messages[0]);
      //   addMessage({ message: "User logged in failed." });
      setLoading(false);
    }
  };



  const contextValue = {
    loading,
    isLoggedIn,
    setLoggedIn,
    authData,
    loginUser:useCallback((payload)=>{
      loginUser(payload)
    },[])
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
