import { Box, Button, Stack } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useHelpDesk } from "../../common/context/helpdesk-context";
import { TruckLoader } from "./admin/truckLoader";
import AddHelpDesk from "./AddHelpDesk";
import { LayoutStyle } from "../layout/style";
import {
  actionColumnFunction,
  getDefaultColDef,
} from "../../common/constant/commonFunction";
import CommonAgGrid from "../../common/constant/commonAgGrid";
import { HelpDeskDataColumn } from "../../common/constant/gridColumns";

export const HelpDeskDealer = () => {
  const classes = LayoutStyle();
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columnFinal, setColumnFinal] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [rowData, setRowData] = useState([]);
  const { getHelpDeskListApi, helpdeskList, loading } = useHelpDesk();
  const navigation = useNavigate();
  const defaultColDef = useMemo(() => getDefaultColDef(), []);

  const handleCreateHelp = (params) => {
    setOpen(true);
  };
  useEffect(() => {
    getHelpDeskListApi({
      limit: 10000,
      offset: 0,
      filter: {
        userId: parseInt(localStorage.getItem("cashflow_admin_user_id")),
      },
    });
  }, []);

  useEffect(() => {
    if (helpdeskList?.length > 0) {
      setRowData(helpdeskList);
    } else {
      setRowData([]);
    }
  }, [helpdeskList]);

  const handleEditButton = (params) => {
    navigation("/user-queries", { state: params });
  };

  useEffect(() => {
    if (HelpDeskDataColumn?.length > 0) {
      const actionColumn = actionColumnFunction({
        selectedRows: selectedRows,
        handleEditButton: handleEditButton,
        deleteButton: true,
        viewButton: true,
      });
      setColumnFinal([...HelpDeskDataColumn, actionColumn]);
    }
  }, [HelpDeskDataColumn]);
  return (
    <Box className={classes.mainContent}>
      <Box className={classes.gridContantFleetList}>
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Box className={classes.headerNameText}>Help Desk</Box>
          <Box className={classes.addEditGrid}>
            <Button
              variant="outlined"
              className={classes.fleetListNewButton}
              onClick={handleCreateHelp}
            >
              Create
            </Button>
          </Box>
        </Stack>
        <Box className={classes.helpDeskGridContant}>
          {loading ? (
            <TruckLoader />
          ) : (
            <CommonAgGrid
              className="ag-theme-quartz ag-grid-helpdesk"
              suppressRowClickSelection
              rowHeight={35}
              onSelectionChanged={(event) => {
                const selectedNodes = event?.api?.getSelectedNodes();
                const selectedRows = selectedNodes?.map((node) => node?.data);
                setSelectedRows(selectedRows);
              }}
              pagination={true}
              rowData={rowData}
              defaultColDef={defaultColDef}
              columnData={columnFinal}
            />
          )}
        </Box>
      </Box>
      {open && (
        <AddHelpDesk
          setRefreshData={setRefreshData}
          setOpen={setOpen}
          open={open}
          title={"Create Ticket"}
        />
      )}
    </Box>
  );
};
