import moment from "moment/moment";
import { addComma, getCurrencyValue } from "../utils/commonFuntions";
import { escapeSpecialChars } from "../utils/excelUpload";
import { SelectedModels } from "../../pages/dashboard/fleet/SelectedModels";
import { numberValueParser } from "./commonFunction";

export const columnData = [
  {
    field: "fleetNo",
    headerName: "Fleet No.",
    headerClass: "super-app-theme--header",
  },
  {
    field: "age",
    headerName: "Age ",
    headerClass: "super-app-theme--header",
  },
  {
    field: "year",
    headerName: "Year ",
    headerClass: "super-app-theme--header",
  },
  {
    field: "make",
    headerName: "Make ",
    headerClass: "super-app-theme--header",
  },
  {
    field: "model",
    headerName: "Model ",
    headerClass: "super-app-theme--header",
  },
  {
    field: "fuel",
    headerName: "Fuel ",
    headerClass: "super-app-theme--header",
  },
  {
    field: "type",
    headerName: "Type ",
    headerClass: "super-app-theme--header",
  },
  {
    field: "driveTrain",
    headerName: "Drive Train ",
    headerClass: "super-app-theme--header",
  },
  {
    field: "cpmCategory",
    headerName: "CPM Category ",
    headerClass: "super-app-theme--header",
  },
  {
    field: "mpg",
    headerName: "MPG ",
    headerClass: "super-app-theme--header",
  },
  {
    field: "vin",
    headerName: "VIN ",
    headerClass: "super-app-theme--header",
  },
  {
    field: "drivername",
    headerName: "Driver Name ",
    headerClass: "super-app-theme--header",
  },
  {
    field: "totalMiles",
    headerName: "Total Miles ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(Math.round(params?.value));
    },
  },
  {
    field: "annualMileage",
    headerName: "Annual Mileage ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(Math.round(params?.value));
    },
  },
  {
    field: "monthlyMileage",
    headerName: "Monthly Mileage ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(Math.round(params?.value));
    },
  },
  {
    field: "state",
    headerName: "Location",
    headerClass: "super-app-theme--header",
  },
];

export const replacementColumnData = [
  {
    field: "fleetNo",
    headerName: "Fleet No.",
    headerClassName: "super-app-theme--header",
    minWidth: 100,
  },
  {
    field: "age",
    headerName: "Age ",
    headerClassName: "super-app-theme--header",
    minWidth: 100,
  },
  {
    field: "totalMiles",
    headerName: "Vehicle Total Miles",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(params?.value);
    },
    minWidth: 100,
  },
  {
    field: "annualMileage",
    headerName: "Vehicle Annual Mileage ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(params?.value);
    },
    minWidth: 100,
  },
  {
    field: "year1",
    headerName: "Year 1",
    headerClassName: "super-app-theme--header",
    minWidth: 100,
  },
  {
    field: "year2",
    headerName: "Year 2",
    headerClassName: "super-app-theme--header",
    minWidth: 100,
  },
  {
    field: "year3",
    headerName: "Year 3",
    headerClassName: "super-app-theme--header",
    minWidth: 100,
  },
  {
    field: "year4",
    headerName: "Year 4",
    headerClassName: "super-app-theme--header",
    minWidth: 100,
  },
  {
    field: "year5",
    headerName: "Year 5",
    headerClassName: "super-app-theme--header",
    minWidth: 100,
  },
];

export const maintenanceColumnData = [
  {
    field: "fleetNo",
    headerName: "Fleet No.",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "totalMiles",
    headerName: "Vehicle Total Miles",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(params?.value);
    },
  },
  {
    field: "annualMileage",
    headerName: "Vehicle Annual Mileage ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(params?.value);
    },
  },
  {
    field: "currentCostPerMile",
    headerName: "Current Cost Per Mile",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "currentYearTotalCost",
    headerName: "Current Year Total Cost",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_1_CostPerMile",
    headerName: "1st Year Cost Per Mile",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_1_TotalCost",
    headerName: "1st Year Total Cost",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_2_CostPerMile",
    headerName: "2nd Year Cost Per Mile",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_2_TotalCost",
    headerName: "2nd Year Total Cost",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_3_CostPerMile",
    headerName: "3rd Year Cost Per Mile",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_3_TotalCost",
    headerName: "3rd Year Total Cost",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_4_CostPerMile",
    headerName: "4th Year Cost Per Mile",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_4_TotalCost",
    headerName: "4th Year Total Cost",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_5_CostPerMile",
    headerName: "5th Year Cost Per Mile",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_5_TotalCost",
    headerName: "5th Year Total Cost",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
];

export const FuelcolumnData = [
  {
    field: "fleetNo",
    headerName: "Fleet No.",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "fuelPriceLocation",
    headerName: "Fuel Price Location ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "date",
    headerName: "Fuel Price Date ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return params?.value
        ? moment(params.value).format("MM/DD/YYYY HH:mm:ss")
        : "";
    },
  },
  {
    field: "year",
    headerName: "Year ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "age",
    headerName: "Age ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "model",
    headerName: "Model ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "annualMileage",
    headerName: "Vehicle Annual Mileage ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(params?.value);
    },
  },
  {
    field: "averageFuelPrice",
    headerName: "Average Fuel Price ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "totalMiles",
    headerName: "Vehicle Total Mileage ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(params?.value);
    },
  },
  {
    field: "originalFuelEconomy",
    headerName: "Original Fuel Economy ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "cyclingFuelEconomy",
    headerName: "Cycling Fuel Economy ",
    headerClassName: "super-app-theme--header",
  },
];

export const FundingColumnData = [
  {
    field: "fleetNo",
    headerName: "Fleet No.",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "year",
    headerName: "Year ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "make",
    headerName: "Make ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "model",
    headerName: "Model ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "newYear",
    headerName: "New Vehicle Year ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "newMake",
    headerName: "New Vehicle Make ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "newModel",
    headerName: "New Vehicle Model ",
    headerClassName: "super-app-theme--header",
  },
  // do not remove the below commented code
  // {
  //   field: "newFuel",
  //   headerName: "Fuel",
  //   headerClassName: "super-app-theme--header",
  // },
  {
    field: "newMpg",
    headerName: "New MPG",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "paymentAmount",
    headerName: "Payment Amount ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "fundingType",
    headerName: "Funding Type",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "newVehicleCost",
    headerName: "New Vehicle Cost ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "newEstimatedResaleValue",
    headerName: "New Estimated Resale Value ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "residualPercentage",
    headerName: "Residual % ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "equityGain",
    headerName: "Equity Gain",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
];

export const ManageUserColumnData = [
  {
    field: "name",
    headerName: "Name",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "userName",
    headerName: "Username",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "password",
    headerName: "Password",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "phone",
    headerName: "Phone",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "userTypeName",
    headerName: "User Type",
    headerClassName: "super-app-theme--header",
    cellClass: "TypeClass",
  },
];

export const CompaniesColumnData = [
  {
    field: "companyName",
    headerName: "Company Name",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
];

export const MakeColumnData = [
  {
    field: "name",
    headerName: "Name",
    headerClassName: "super-app-theme--header",
  },
];

export const ModelColumnData = [
  {
    field: "model",
    headerName: "Name",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "make",
    headerName: "Make",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "year",
    headerName: "Year",
    headerClassName: "super-app-theme--header",
  },
];

export const YearColumnData = [
  {
    field: "year",
    headerName: "Year",
    headerClassName: "super-app-theme--header",
  },
];

function CheckIsModel(params) {
  if (params.value === false) {
    return "No";
  } else if (params.value) {
    return "Yes";
  }
}

export const UploadCsvDataColumn = [
  {
    field: "year",
    headerName: "Year",
    headerClassName: "super-app-theme--header",
    cellEditor: "agNumberCellEditor",
    valueParser: numberValueParser,
    editable: true,
    minWidth: 90,
  },
  {
    field: "totalMiles",
    headerName: "Total Miles",
    headerClassName: "super-app-theme--header",
    cellEditor: "agNumberCellEditor",
    valueParser: numberValueParser,
    editable: true,
  },
  {
    field: "make",
    headerName: "Make ",
    headerClassName: "super-app-theme--header",
    editable: true,
  },
  {
    field: "model",
    headerName: "Model ",
    headerClassName: "super-app-theme--header",
    editable: true,
  },
  {
    headerName: "Closest Model",
    field: "language",
    headerClassName: "super-app-theme--header",
    cellRenderer: SelectedModels,
    cellEditor: "agRichSelectCellEditor",
    cellEditorParams: {
      values: ["Male", "Female"],
      cellRenderer: SelectedModels,
    },
  },
  {
    headerName: "Selected Model",
    field: "selectedModelRow",
    headerClassName: "super-app-theme--header",
    cellRenderer: CheckIsModel,
  },
  {
    field: "vin",
    headerName: "VIN ",
    headerClassName: "super-app-theme--header",
    editable: true,
  },
  {
    field: "stateCode",
    headerName: "State Code",
    headerClassName: "super-app-theme--header",
    editable: true,
    minWidth: 90,
  },
  {
    field: "company",
    headerName: "Company",
    headerClassName: "super-app-theme--header",
    editable: true,
    valueFormatter: (params) => escapeSpecialChars(params?.value),
  },
];

export const fuelPriceColumnData = [
  {
    field: "State",
    headerName: "State",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Regular",
    headerName: "Regular Price",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Mid-Grade",
    headerName: "Mid-Grade Price",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Premium",
    headerName: "Premium Price",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "Diesel",
    headerName: "Diesel Price",
    minminWidth: 160,
  },
];
export const fuelEconomyColumn = [
  {
    field: "make",
    headerName: "Make",
    minWidth: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "model",
    headerName: "Model",
    minWidth: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "year",
    headerName: "Year",
    minWidth: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "mpg",
    headerName: "MPG",
    minWidth: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "id",
    headerName: "Government ID",
    minWidth: 200,
    headerClassName: "super-app-theme--header",
  },
];

export const UploadCsvMpgDataColumn = [
  {
    field: "mpg",
    headerName: "Mpg",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "make",
    headerName: "Make ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "model",
    headerName: "Model ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "year",
    headerName: "Year ",
    headerClassName: "super-app-theme--header",
  },
];

export const HelpDeskDataColumn = [
  { headerName: "ID", field: "id" },
  { headerName: "Name", field: "name" },
  { headerName: "Date", field: "date" },
  { headerName: "Image", field: "image" },
  { headerName: "Description", field: "description" },
  { headerName: "Status", field: "status" },
];

export const getColorChange = (params) => {
  if (params.data.color !== "") {
    return { background: "#F77479", color: "black" };
  } else if (params.data.modelDifference) {
    return { background: "#F4F5A2", color: "black" };
  } else {
    return "";
  }
};
