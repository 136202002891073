import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import Paper from "@mui/material/Paper";
import { LayoutStyle } from "./style";
import { Typography, Container, Box } from "@mui/material";
import { useTheme } from "@emotion/react";

const Footer = () => {
  const [value, setValue] = React.useState(0);
  const classes = LayoutStyle();
  const theme = useTheme();
  const [theme1, setTheme] = React.useState(theme.palette.mode);
  React.useEffect(() => {
    setTheme(theme.palette.mode);
  }, [theme]);

  return (
    <>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 9999 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          className={
            theme1 === "dark" ? classes.bottomDarkNav : classes.bottomNav
          }
        >
          <Container maxWidth="100%">
            <Box className={classes.footerNav}>
              <Typography
                variant="h4"
                noWrap
                component="Box"
                fontWeight="bold"
                sx={{ display: { xs: "none", sm: "block", lg: "flex" } }}
              >
                <img
                  src="/white-logo.png"
                  width={"120px"}
                  alt="logo"
                  style={{ padding: 2 }}
                />
              </Typography>
              <Box>&#169; Copyright 2024 Hyundai. All rights reserved.</Box>
              <Box>Version 1.0</Box>
            </Box>
          </Container>
        </BottomNavigation>
      </Paper>
    </>
  );
};

export default Footer;
