import React, { useState, createContext, useCallback, useContext } from "react";
import api from "../../configs/api";


/**
 * Fuel list context
 */
export const FuelEconomyContext = createContext();

/**
 * Fuel list provider
 */
export const FuelEconomyProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [fuelEconomy, setFuelEconomy] = useState([]);
  const [fuelEconomyById, setFuelEconomyById] = useState(null);

  const getFuelEconomyApi = async (values) => {
    setLoading(true);
    const response = await api.fuelEconomyApi.getFuelEconomyList({
      limit: values?.limit ? values.limit : 10000,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setFuelEconomy(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const getFuelEconomyByIdApi = async (id) => {
    setLoading(true);
    const response = await api.fuelEconomyApi.getFuelEconomyById(id);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setFuelEconomyById(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const createFuelEconomyApi = async (values) => {
    setLoading(true);
    const response = await api.fuelEconomyApi.createFuelEconomy(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setFuelEconomy(response?.data?.data);
      getFuelEconomyApi();
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const editFuelEconomyApi = async (values) => {
    setLoading(true);
    const response = await api.fuelEconomyApi.updateFuelEconomy(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      getFuelEconomyApi();
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const deleteFuelEconomyByIdApi = async (values) => {
    setLoading(true);
    const response = await api.fuelEconomyApi.deleteFuelEconomy(values?.id);
    if (response?.error) {
      setLoading(false);
    }
    if (response?.data?.data) {
      getFuelEconomyApi();
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const fuelEconomyCreateMultiple = async (values) => {
    setLoading(true);
    const response = await api.fuelEconomyApi.createMultipleFuelEconomy(values);
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const contextValue = {
    loading,
    setLoading,
    fuelEconomy,
    setFuelEconomy,
    fuelEconomyById,
    setFuelEconomyById,
    getFuelEconomyApi: useCallback((request) => {
      getFuelEconomyApi(request);
    }, []),
    getFuelEconomyByIdApi: useCallback((request) => {
      getFuelEconomyByIdApi(request);
    }, []),
    createFuelEconomyApi: useCallback((request) => {
      createFuelEconomyApi(request);
    }, []),
    editFuelEconomyApi: useCallback((request) => {
      editFuelEconomyApi(request);
    }, []),
    deleteFuelEconomyByIdApi: useCallback((request) => {
      deleteFuelEconomyByIdApi(request);
    }, []),
    fuelEconomyCreateMultiple: useCallback((request) => {
      fuelEconomyCreateMultiple(request);
    }, []),
  };

  return (
    <FuelEconomyContext.Provider value={contextValue}>
      {children}
    </FuelEconomyContext.Provider>
  );
};

export const useFuelEconomy = () => useContext(FuelEconomyContext);