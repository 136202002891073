export const routes = {
  // Auth routes
  LOGIN: "/",
  SIGN_UP: "/sign-up",
  FORGOT_PASSWORD: "/forgot-password",

  //Dashboard routes
  DASHBOARD: "/dashboard",
  FLEET: "/fleet-list",
  REPLACEMENT: "/replacement-schedule",
  MAINTENANCE: "/maintenance-analysis",
  FUEL: "/fuel-analysis",
  FUNDING: "/funding-new-vehicle",
  ANALYSIS_RESULTS: "/analysis-results",
  ANALYSIS_DATA: "/analysis-data",
  FUEL_PRICE: "/fuel-price",
  FUEL_ECONOMY: "/fuel-economy",
  REPORT: "/report",
  MANAGE_COMPANY: "/manage-company",
  MANAGE_DEALER: "/manage-dealer",
  MANEGE_USERS: "/manage-user",
  MANAGE_VEHICLE: "/manage-vehicle",
  NEW_FLEET_LIST: "/new-fleetlist",
  ANALYSIS_DATA: "/analysisData",
  ANALYSIS_REPORT: "/analysis-report",
  NOT_FOUND: "*",
  // USER_SETTING: "/userSetting",
  USER_PROFILE: "/Profile",
  ADMIN_HELPDESK: "/userQueries",
  USER_HELPDESK: "/helpDesk",
  ADMIN_VIEW_QUERIES: "/user-queries",
  VEHICLE_COMPARISION: "/vehicleComparision",
};
